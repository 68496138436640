import styled from 'styled-components'

export const WordsSelected = styled.span`
    margin: 0 0.3em;
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
    color: #fff;
    background-color: #007bff;
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color .15s;
    cursor: pointer;
`;

export const WordsUnselected = styled.span`
    margin: 0 0.3em;
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
    border: 1px solid #007bff;
    color: #007bff;
    background-color: #fff;
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color .15s;
    cursor: pointer;
`;