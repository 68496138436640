import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Button, Card, Container, Row, Col, Navbar } from 'react-bootstrap';
import alert from '../components/common/alert';
import { appFetch } from '../services/fetch';

const Link = styled.a`
    color: #007bff;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const emailRgx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;

const ResetPassword = (): JSX.Element => {

    const [form, setForm] = useState({
        email: ''
    })
    const [validate, setValidate] = useState({
        email: {
            message: '',
            ok: true
        }
    })

    const isValid = () => {
        const emailStatus = form.email.match(emailRgx) ? true : false

        setValidate({
            email: {
               message: emailStatus ? '' : 'Digite um email válido',
               ok: emailStatus
            }
        })

        return emailStatus
    }

    const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (isValid()) {
            const { email } = form
            const body = { email }          
            try {
                await appFetch('user/reset-password', 'POST', {}, body)
                await alert({
                    title: 'Sua senha foi resetada com sucesso!',
                    text: 'Acesse seu email para ter acesso a nova senha',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                })
                setForm({ email: '' })
            } catch (error) {
                await alert({
                    title: 'Não foi possível resetar sua senha.',
                    text: 'Tente novamente',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValidate({
            ...validate,
            [event.target.name]: {
                message: '',
                ok: true
            }
        })
        setForm({...form, [event.target.name]: event.target.value})
    }

    return (
        <React.Fragment>
            <Navbar style={{ margin: 0, borderBottom: '1px solid #e7e7e7' }} bg="dark">
                <Navbar.Brand>
                    <span>
                        <span style={{ color: '#fff' }}>&nbsp;Level Group Admin</span>
                    </span>
                </Navbar.Brand>
            </Navbar>
            <Container>
                <div style={{ marginTop: '4rem' }}>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8}>
                            <Card>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="Email"
                                                value={form.email}
                                                onChange={handleChange}
                                                isInvalid={!validate.email.ok}
                                            />
                                            <Form.Control.Feedback type="invalid">{validate.email.message}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Button className="btn-primary" type="submit">
                                            Enviar
                                        </Button>
                                    </Form>
                                    <div style={{ textAlign: 'center' }}><Link href="/login">Voltar para o Login</Link></div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default ResetPassword