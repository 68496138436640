import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';
import { appFetch } from '../../services/fetch';
import { Template } from '../../models/template';
import ReactLoading from 'react-loading';
import alert from '../../components/common/alert';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type TParams = { id: string };

interface Validate {
    [key: string]: {
        ok: boolean,
        message: string
    }
}

const TemplateSimilarityRules = ({ match, history }: RouteComponentProps<TParams>): JSX.Element => {
    const [template, setTemplate] = useState({} as Template);
    const [isLoading, setIsLoading] = useState(false);
    const [formValidation, setFormValidation] = useState({
        ok: true,
        message: <div></div>
    });
    const [validate, setValidate] = useState({} as Validate);
    const keyRef = useRef() as MutableRefObject<HTMLSelectElement>;

    const { id } = match.params;

    const breadcrumb = {
        path: `/templates/${id}/similarity-rules`,
        value: 'Regras de Similaridade'
    }

    const breadcrumbs = [
        {
            path: '/',
            value: 'Home'
        },
        {
            path: '/templates',
            value: 'Templates'
        },
        breadcrumb
    ]

    useEffect(() => {
        if (id) getTemplate(id)
    }, [])

    const getTemplate = async (id: string) => {
        setIsLoading(true)

        try {
            const template = await appFetch(`template/${id}`, 'GET') as Template;
            setValidate(template.similarityRules
                ? template.similarityRules?.reduce((acc, curr) => {
                    return {
                        ...acc,
                        [`apiRef-${curr.rule}`]: {
                            ok: true,
                            message: ''
                        },
                        [`suggestedRuleName-${curr.rule}`]: {
                            ok: true,
                            message: ''
                        }
                    }
                }, {})
                : {}
            )
            setTemplate(template)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível recuperar as informações do template',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
            history.push('/templates')
        }
    }

    const addSimilarityRule = () => {
        if (keyRef.current.value) {
            setFormValidation({
                ok: true,
                message: <div></div>
            })
    
            const updatedValidate = Object.keys(validate).reduce((acc, curr) => ({
                ...acc,
                [curr]: {
                    ok: true,
                    message: ''
                }
            }), {})
    
            setValidate({
                ...updatedValidate,
                [`apiRef-${keyRef.current.value}`]: {
                    ok: true,
                    message: ''
                },
                [`suggestedRuleName-${keyRef.current.value}`]: {
                    ok: true,
                    message: ''
                }
            })

            const similarityRules = template.similarityRules ? template.similarityRules : []
            const { json } = template.rules?.find(rule => rule._id === keyRef.current.value) || { json: '' }
            const isAlreadyAdded = template.similarityRules?.some(similarityRule => similarityRule.rule === keyRef.current.value)
            
            if (json && !isAlreadyAdded) {
                setTemplate({
                    ...template,
                    similarityRules: [
                        ...similarityRules,
                        {
                            rule: keyRef.current.value,
                            key: json,
                            apiRef: '',
                            suggestedRuleName: '',
                            removeMask: false,
                            isDate: false,
                            isName: false,
                        }
                    ]
                })
            }
        }
    }

    const save = async () => {
        if (isValidForm()) {
            const { similarityRules } = template;
            try {
                await appFetch(`template/update-similarity-rules/${template._id}`, 'PUT', {},  { similarityRules }) as Template
                await alert({
                    text: 'Regras salvas com sucesso',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                })
                history.push('/templates')
            } catch (error) {
                await alert({
                    text: 'Não foi possível salvar as regras',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        } else {
            const invalidSimilarityRules = template.similarityRules
                ? template.similarityRules
                    .filter(similarityRule => !similarityRule.key || !similarityRule.key || !similarityRule.suggestedRuleName)
                    .map(similarityRule => similarityRule.key)
                : []
            setFormValidation({
                ok: false,
                message: template.similarityRules && template.similarityRules.length > 0 
                ? <span style={{ fontSize: '0.8rem' }}>Antes de submeter preencha todos os campos de <strong>
                    {
                        invalidSimilarityRules.reduce((acc, curr, index) => {
                            if (index === 0) return acc + curr
                            if (index === invalidSimilarityRules.length - 1) return acc + ' e ' + curr
                            return acc + ', ' + curr
                        }, '')
                    }
                    </strong>
                </span>
                : <span style={{ fontSize: '0.8rem' }}>Escolha pelo menos uma regra para submeter</span>
            })

            const updatedValidate = template.similarityRules
                ? template.similarityRules.reduce((acc, curr) => {
                    return {
                        ...acc,
                        [`apiRef-${curr.rule}`] : {
                            ok: curr.apiRef ? curr.apiRef : false,
                            message: 'O campo precisa ser preenchido.'
                        },
                        [`suggestedRuleName-${curr.rule}`] : {
                            ok: curr.suggestedRuleName ? curr.suggestedRuleName : false,
                            message: 'O campo precisa ser preenchido.' 
                        }
                    }
                }, {})
                : {}

            setValidate({
                ...updatedValidate
            })
        }
    }

    const isValidForm = () => {
        return template.similarityRules && !template.similarityRules.some(similarityRule => !similarityRule.key || !similarityRule.key || !similarityRule.suggestedRuleName)
    }

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const similarityRules = template.similarityRules
        ? template.similarityRules.map(similarityRule => {
            if (similarityRule.rule === event.target.id) return {
                ...similarityRule,
                [event.target.name]: event.target.checked
            }

            return similarityRule
        }) : []
    
        setTemplate({
            ...template,
            similarityRules
        })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const similarityRules = template.similarityRules
            ? template.similarityRules.map(similarityRule => {
                if (similarityRule.rule === event.target.id) return {
                    ...similarityRule,
                    [event.target.name]: event.target.value
                }

                return similarityRule
            }) : []
        
        setTemplate({
            ...template,
            similarityRules
        })

        setValidate({
            ...validate,
            [`${event.target.name}-${event.target.id}`]: {
                ok: true,
                message: ''
            }
        })
    }

    const removeSimilarityRule = (id: string) => {
        setTemplate({
            ...template,
            similarityRules: template.similarityRules?.filter(similarityRule => similarityRule.rule !== id)
        })
    }

    return (
        <React.Fragment>
            {isLoading &&
            <div style={{ width: 'calc(100% - 251px)', height: 'calc(100vh - 57px)', position: 'absolute', zIndex: 1, backgroundColor: '#000', opacity: 0.2 }}>
                <ReactLoading className='spinner-margin' type={'spin'} color={'#007bff'} height={'250px'} width={'250px'} />
            </div>}
            <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
                <Row>
                    <Col><h1>Regras de Similaridade</h1></Col>
                    <Col>
                        <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                    </Col>
                </Row>
                <hr/>
                <br/>
                <Row>
                    <Col>
                        <Form noValidate>
                            <Row>
                                <Col>
                                    <Form.Group controlId="key">
                                        <Form.Label>Regras</Form.Label>
                                        <Form.Control
                                            ref={keyRef}
                                            as="select"
                                            name="key"
                                        >
                                            {template.rules?.map(option => (<option key={option._id} value={option._id}>{option.json}</option>))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs="auto" style={{ paddingLeft: '0px', display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ cursor: 'pointer' }} onClick={addSimilarityRule}/>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} md={3}>
                        <Button className="btn-primary" type="submit" style={{ width: '100%' }} onClick={save}>
                            Salvar
                        </Button>
                    </Col>
                    <Col xs={12} md={9}>
                        {!formValidation.ok && formValidation.message}
                    </Col>
                </Row>
                <br/>
                <div style={{ height: '480px', overflowY: 'scroll', overflowX: 'hidden', marginBottom: '20px', marginTop: '10px' }}>
                    <Row>
                        <Col>
                            {template.similarityRules && template.similarityRules.length > 0 && template.similarityRules.map(similarityRule => 
                            (<Card key={similarityRule.rule} style={{ width: '100%', marginBottom: '1rem' }}>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Card.Title><strong>{template.rules?.find(rule => rule._id === similarityRule.rule)?.json}</strong></Card.Title>
                                        </Col>
                                        <Col style={{ textAlign: 'end' }}>
                                            <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} onClick={() => removeSimilarityRule(similarityRule.rule)}/>
                                        </Col>
                                    </Row>
                                    <Form noValidate>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="apiRef">
                                                    <Form.Label>Nome da regra na API</Form.Label>
                                                    <Form.Control
                                                        name="apiRef"
                                                        id={similarityRule.rule}
                                                        type="text"
                                                        value={similarityRule.apiRef}
                                                        onChange={handleChange}
                                                        isInvalid={!validate[`apiRef-${similarityRule.rule}`].ok}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{validate[`apiRef-${similarityRule.rule}`].message}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="suggestedRuleName">
                                                    <Form.Label>Nome sugerido para regra</Form.Label>
                                                    <Form.Control
                                                        name="suggestedRuleName"
                                                        id={similarityRule.rule}
                                                        type="text"
                                                        value={similarityRule.suggestedRuleName}
                                                        onChange={handleChange}
                                                        isInvalid={!validate[`suggestedRuleName-${similarityRule.rule}`].ok}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{validate[`suggestedRuleName-${similarityRule.rule}`].message}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group controlId="isDate">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="É data"
                                                        name="isDate"
                                                        id={similarityRule.rule}
                                                        checked={similarityRule.isDate}
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group controlId="removeMask">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Remover máscara"
                                                        name="removeMask"
                                                        id={similarityRule.rule}
                                                        checked={similarityRule.removeMask}
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group controlId="isName">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="É nome de pessoa"
                                                        name="isName"
                                                        id={similarityRule.rule}
                                                        checked={similarityRule.isName}
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>))}
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TemplateSimilarityRules;