import { BlocksToRender } from "../../models/blocksToRender";
import { BoundingBox } from "../../models/boundingBox";
import { Rule } from "../../models/rule";


export const getImgWithBlocksSrc = async (imageSrc: string, wordsBoundingBoxes: BoundingBox[], rules: Rule[], blocksToRender: BlocksToRender): Promise<string> => {
    interface Dimensions {
        width: number,
        height: number
    }

    const img = document.createElement('img');
    img.src = imageSrc;

    const getImageDimensions = (img: HTMLImageElement) => {
        return new Promise ((resolved) => {
          img.onload =() => {
            resolved({width: img.width, height: img.height})
          };
        })
      }
    const dimensions = await getImageDimensions(img) as Dimensions;
  
    const canvas = document.createElement('canvas');
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height);

      if (blocksToRender.words) {
        wordsBoundingBoxes.map((boundingBox: BoundingBox) => {
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'orange';

          const x = boundingBox.vertices[0].x;
          const y = boundingBox.vertices[0].y;
          const w = boundingBox.vertices[1].x-boundingBox.vertices[0].x;
          const h = boundingBox.vertices[2].y-boundingBox.vertices[0].y;

          ctx.strokeRect(x, y, w, h);
        }); 
      }

      if (blocksToRender.rules) {
        rules.map((rule: Rule) => {
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'green';

          const lastWord = rule.content.right[rule.content.right.length - 1] || rule.content.center
          const firstWord = rule.content.left[0] || rule.content.center

          const x = firstWord.vertices[0].x;
          const y = firstWord.vertices[0].y;
          const w = lastWord.vertices[1].x-firstWord.vertices[0].x;
          const h = lastWord.vertices[2].y-lastWord.vertices[0].y;

          ctx.strokeRect(x, y, w, h);
        });

        // rules.map((rule: Rule) => {
        //   ctx.lineWidth = 1;
        //   ctx.strokeStyle = 'blue';

        //   const lastWordDelimiterRight = rule.delimiterRight.words[rule.delimiterRight.words.length - 1]
        //   const firstWordDelimiterRight = rule.delimiterRight.words[0]

        //   const xDelimiterRight = firstWordDelimiterRight.vertices[0].x;
        //   const yDelimiterRight = firstWordDelimiterRight.vertices[0].y;
        //   const wDelimiterRight = lastWordDelimiterRight.vertices[1].x-firstWordDelimiterRight.vertices[0].x;
        //   const hDelimiterRight = lastWordDelimiterRight.vertices[2].y-lastWordDelimiterRight.vertices[0].y;

        //   ctx.strokeRect(xDelimiterRight, yDelimiterRight, wDelimiterRight, hDelimiterRight);

        //   const lastWordDelimiterLeft = rule.delimiterLeft.words[rule.delimiterLeft.words.length - 1]
        //   const firstWordDelimiterLeft = rule.delimiterLeft.words[0]

        //   const xDelimiterLeft = firstWordDelimiterLeft.vertices[0].x;
        //   const yDelimiterLeft = firstWordDelimiterLeft.vertices[0].y;
        //   const wDelimiterLeft = lastWordDelimiterLeft.vertices[1].x-firstWordDelimiterLeft.vertices[0].x;
        //   const hDelimiterLeft = lastWordDelimiterLeft.vertices[2].y-lastWordDelimiterLeft.vertices[0].y;

        //   ctx.strokeRect(xDelimiterLeft, yDelimiterLeft, wDelimiterLeft, hDelimiterLeft);
        // }); 
      }
    }

    return canvas.toDataURL();
}