import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import User from '../../models/user';
import { RouteComponentProps } from 'react-router-dom';
import { Validate } from '../../models/validate';
import { appFetch } from '../../services/fetch';
import alert from '../../components/common/alert';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';

type TParams = { id: string };

function UserForm({ history, match }: RouteComponentProps<TParams>): JSX.Element {

    const { id } = match.params;
    const action = id ? 'Editar' : 'Cadastrar'


    const breadcrumb = {
        path: id ? `/users/${id}/edit` : '/users/add',
        value: id ? 'Editar Usuário' : 'Cadastrar Usuário'
    }

    const breadcrumbs = [
        {
            path: '/',
            value: 'Home'
        },
        {
            path: '/users',
            value: 'Usuários'
        },
        breadcrumb
    ]

    const [user, setUser] = useState<User>({} as User);

    useEffect(() => {
        if (id) getUser(id);
    }, [])

    useEffect(() => {
        setForm({
            name: user.name || '',
            email: user.email || '',
            cpf: user.cpf || '',
            password: user.password || '',
            active: user.active ? 'ATIVO' : (user.active === false ? 'INATIVO' : '')
        })
    }, [user])

    const getUser = async (id: string) => {
        const user = await appFetch(`user/${id}`, 'GET') as User;
        setUser(user)
    }

    const [form, setForm] = useState({
        name: user.name || '',
        email: user.email || '',
        cpf: user.cpf || '',
        password: user.password || '',
        active: user.active ? 'ATIVO' : (user.active === false ? 'INATIVO' : '')
    })

    const [validate, setValidate] = useState({
        name: {
            message: '',
            ok: true
        },
        cpf: {
            message: '',
            ok: true
        },
        email: {
            message: '',
            ok: true
        },
        password: {
            message: '',
            ok: true
        },
        active: {
            message: '',
            ok: true
        },
    } as Validate)

    const isValid = () => {
        const validations = {
            name: {
                message: form.name ? '' : 'Esse campo é obrigatótio',
                ok: form.name ? true : false
            },
            cpf: {
                message: form.cpf ? '' : 'Esse campo é obrigatótio',
                ok: form.cpf ? true : false
            },
            email: {
                message: form.email ? '' : 'Esse campo é obrigatótio',
                ok: form.email ? true : false
            },
            password: {
                message: (action === 'Cadastrar' ? form.password : true) ? '' : 'Esse campo é obrigatótio',
                ok: (action === 'Cadastrar' ? form.password : true) ? true : false
            },
            active: {
                message: form.active ? '' : 'Esse campo é obrigatótio',
                ok: form.active ? true : false
            }

        } as Validate
        setValidate(validations)

        return Object.keys(validations).every(key => validations[key as keyof Validate].ok)
    }

    const createUser = async () => {
        if (isValid()) {
            const body = {
                name: form.name,
                email: form.email,
                cpf: form.cpf,
                password: form.password,
                active: form.active === 'ATIVO'
            }
            try {
                await appFetch('user/', 'POST', {} , body);

                await alert({
                    text: 'Usuário criado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                })

                history.push('/users')
            } catch (error) {
                await alert({
                    text: 'Não foi possível criar o usuário',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const updateUser = async () => {
        if (isValid()) {
            const body = {
                name: form.name,
                email: form.email,
                cpf: form.cpf,
                password: form.password || user.password,
                active: form.active === 'ATIVO'
            }
            try {
                await appFetch(`user/${match.params.id}`, 'PUT', {}, body);
                
                await alert({
                    text: 'Usuário salvo com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                })

                history.push('/users')
            } catch (error) {
                await alert({
                    text: 'Não foi possível salvar o usuário',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (id) {
            updateUser();
        } else {
            createUser();
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValidate({
            ...validate,
            [event.target.name]: {
                message: '',
                ok: true
            }
        })
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    return (
        <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
            <Row>
                <Col><h1>{`${action} Usuário`}</h1></Col>
                <Col>
                    <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                </Col>
            </Row>
            <hr/>
            <br />
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        value={form.name}
                                        onChange={handleChange}
                                        isInvalid={!validate.name.ok}
                                    />
                                    <Form.Control.Feedback type="invalid">{validate.name.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicCpf">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control
                                        name="cpf"
                                        type="text"
                                        value={form.cpf}
                                        onChange={handleChange}
                                        isInvalid={!validate.cpf.ok}
                                    />
                                    <Form.Control.Feedback type="invalid">{validate.cpf.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        value={form.email}
                                        onChange={handleChange}
                                        isInvalid={!validate.email.ok }
                                    />
                                    <Form.Control.Feedback type="invalid">{validate.email.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        value={form.password}
                                        onChange={handleChange}
                                        isInvalid={!validate.password.ok}
                                    />
                                    <Form.Control.Feedback type="invalid">{validate.password.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicActive">
                                    <Form.Label>Ativo</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="active"
                                        value={form.active}
                                        onChange={handleChange}
                                        isInvalid={!validate.active.ok}
                                    >
                                        <option value=''>Selecione uma opção...</option>
                                        <option value='ATIVO'>Sim</option>
                                        <option value='INATIVO'>Não</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{validate.active.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button className="btn-primary" type="submit" style={{ marginTop: '1rem' }}>
                            Salvar
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>)
}

export default UserForm;



