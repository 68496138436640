import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BaseProps from '../../models/baseProps';
import TemplateList from './TemplateList';
import TemplateForm from './TemplateForm';
import TemplateExtract from './TemplateExtract';
import TemplateDetail from './TemplateDetail';
import TemplateSimilarityRules from './TemplateSimilarityRules';

interface TemplateProps extends BaseProps {
    setRedirectTo(path: string): void
}

const Template = ({ history, redirectTo, setRedirectTo }: TemplateProps): JSX.Element => {
    useEffect(() => {
        setRedirectTo('')
        if(redirectTo) history.push(redirectTo)
    }, [redirectTo]);

    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}/add`}
                render={props => <TemplateForm {...props} />}
            />
            <Route
                exact
                path={`${path}/:id/edit`}
                render={props => <TemplateForm {...props} />}
            />
            <Route
                exact
                component={TemplateDetail}
                path={`${path}/:id/detail`} />
            <Route
                exact
                path={`${path}/:id/extract`}
                render={props => <TemplateExtract {...props} />}
            />
            <Route
                exact
                path={path}
                render={props => <TemplateList {...props} />}
            />
            <Route
                exact
                path={`${path}/:id/similarity-rules`}
                render={props => <TemplateSimilarityRules {...props} />}
            />
        </Switch>
    )
}

export default Template;