import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomBreadcrumb from '../components/common/Breadcrumb/CustomBreadcrumb';
import BaseProps from '../models/baseProps';
import alert from '../components/common/alert';
import { appFetch } from '../services/fetch';
import Url from '../models/url';
import ReactLoading from 'react-loading';
import ReactJson from 'react-json-view';
import { TemplatePagination } from '../models/templatePagination';

interface SimulationProps extends BaseProps {
    setRedirectTo(path: string): void,
}

interface ExtractedRule {
    [key: string]: string
}

interface Json {
    file: string,
    extractedRules: ExtractedRule
}

interface Prediction {
    name: string,
    accuracy: number,
    version: string
}

const Link = styled.a`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const breadcrumbs = [
    {
        path: '/',
        value: 'Home'
    },
    {
        path: '/simulation',
        value: 'Simulação'
    }
]

const extensionsAllowed = ['png', 'jpg', 'jpeg', 'pdf', 'zip', 'rar', 'PDF', 'PNG', 'JPG', 'JPEG'];

interface TemplateTypeOptions {
    value: string,
    label: string
}

const Simulation = ({ history, redirectTo, setRedirectTo }: SimulationProps): JSX.Element => {
    const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const fileInputRefPrediction = useRef() as MutableRefObject<HTMLInputElement>;
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState([] as Json[]);
    const [resultPredction, setResultPrediction] = useState([] as Prediction[]);
    const [templateType, setTemplateType] = useState('');
    const [templateTypes, setTemplateTypes] = useState([] as TemplateTypeOptions[]);
    const [images, setImages] = useState([] as string[]);
    const [imagesPrd, setImagesPrd] = useState([] as string[]);

    useEffect(() => {
        setRedirectTo('')
        if(redirectTo) history.push(redirectTo)
    }, [redirectTo]);

    useEffect(() => {
        getTemplates();
    }, []);

    const getTemplates = async () => {
        try {
            const { docs } = await appFetch('template?page=1&limit=10000', 'GET') as TemplatePagination;
            setTemplateTypes([
                { value: '', label: 'Selecione um tipo de template...' },
                ...docs.map(doc => ({ value: doc._id, label: doc.name }))
            ])
        } catch (error) {
            await alert({
                text: 'Não foi possível carregar os tipo de template',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const readAsDataURL = (file: File) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = () => {
              resolve(fr.result);
            }
            fr.readAsDataURL(file);
        });
    };

    const hasInvalidExtensions = (files: FileList) => {
        return Array.from(files).some(file => {
            const extension = file.name.split('.').pop() || ''
            return !extensionsAllowed.includes(extension)
        })
    }

    const handleFileInputPrediction = async (file: File) => {
        setIsLoading(true)
        const name = file.name
        const data = await readAsDataURL(file);

        const tmpFolder = `level-group-simulation/${uuid()}`;
        const { paths } = await appFetch(`utils/uploadFilesToStorage?FileName=${name}&tmpFolder=${tmpFolder}`, 'POST', {}, { data }) as Url;
        const pathsToPredict = paths || []

        const results = await Promise.all(pathsToPredict.map(async path =>
            await appFetch(`integration/predict/${uuid()}?&path=${path}`, 'GET') as Prediction));
        setIsLoading(false)

        return results.map(r => ({ name: r.name, accuracy: r.accuracy, version: r.version, src: data }))
    }

    const handleFileInput = async (file: File) => {
        setIsLoading(true)
        const name = file.name
        const data = await readAsDataURL(file)

        const tmpFolder = `level-group-simulation/${uuid()}`;
        const { paths } = await appFetch(`utils/uploadFilesToStorage?FileName=${name}&tmpFolder=${tmpFolder}`, 'POST', {}, { data }) as Url;
        const pathsToExtract = paths || []

        const results = await Promise.all(pathsToExtract.map(async path =>
            await appFetch(`integration/extractor?&path=${path}&templateType=${templateType}`, 'GET') as ExtractedRule));
        setIsLoading(false)
        return results.map(result => ({ file: name, extractedRules: result, src: data })) || [];
    }

    const onFileInputChangePrediction =  async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files: inputedfiles } = event.target;
        if (inputedfiles) {
            if(!hasInvalidExtensions(inputedfiles)) {
                try {
                    const inputFilesResults = await Promise.all(Array.from(inputedfiles).map(async (file) => await handleFileInputPrediction(file)));
                    const mappedInputFilesResults = inputFilesResults.flatMap(result => result);
                    const images = mappedInputFilesResults.map(result => result.src) as string[];
                    setResultPrediction(mappedInputFilesResults.map(r => ({ name: r.name, accuracy: r.accuracy*100, version: r.version })))
                    setImagesPrd(images);
                } catch (error) {
                    setIsLoading(false)
                    fileInputRefPrediction.current.value = '';
                    await alert({
                        title: 'Não foi possével fazer o upload do arquivo',
                        text: 'Tente novamente',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    })
                }
            } else {
                await alert({
                    title: 'Arquivo inválido',
                    text: 'Só serão permitidos upload de arquivos nos formatos png, jpeg, pdf, zip ou rar',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
        fileInputRefPrediction.current.value = '';
    }

    const onFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files: inputedfiles } = event.target;
        if(inputedfiles) {
            if (templateType) {
                if(!hasInvalidExtensions(inputedfiles)) {
                    try {
                        const inputFilesResults = await Promise.all(Array.from(inputedfiles).map(async (file) => await handleFileInput(file)))
                        const mappedInputFilesResults = inputFilesResults.flatMap(result => result);
                        const json = mappedInputFilesResults.map(result => ({ file: result.file, extractedRules: result.extractedRules }));
                        const images = mappedInputFilesResults.map(result => result.src) as string[];
                        setResult(json);
                        setImages(images);
                    } catch (error) {
                        setIsLoading(false)
                        fileInputRef.current.value = '';
                        await alert({
                            title: 'Não foi possével fazer o upload do arquivo',
                            text: 'Tente novamente',
                            icon: 'error',
                            confirmButtonText: 'Ok',
                        })
                    }
                } else {
                    await alert({
                        title: 'Arquivo inválido',
                        text: 'Só serão permitidos upload de arquivos nos formatos png, jpeg, pdf, zip ou rar',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    })
                }
            } else {
                await alert({
                    title: 'Selecione um tipo de template',
                    text: 'A extração só será possível com um tipo de template selecionado',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                }) 
            }
        }
        fileInputRef.current.value = '';
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateType(event.target.value)
    }

    return (
        <React.Fragment>
            {isLoading && <div style={{ width: 'calc(100% - 251px)', height: 'calc(100vh - 57px)', position: 'absolute', zIndex: 1, backgroundColor: '#000', opacity: 0.2 }}>
                <ReactLoading className='spinner-margin' type={'spin'} color={'#007bff'} height={'250px'} width={'250px'} />
            </div>}
            <div className='content-wrapper'>
                <Row>
                    <Col><h1>Simulação</h1></Col>
                    <Col>
                        <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                    </Col>
                </Row>
                <hr/>
                <br/>
                <Row>
                    <Col>
                        <h3>Predição</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ marginBottom: '.5rem' }}><span>Arquivo para predição</span></div>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <div>
                                            <label htmlFor="upload-predction">
                                                <Link>
                                                    <FontAwesomeIcon icon={faFileAlt} /> &nbsp;Selecione um arquivo...
                                                </Link>
                                                <input
                                                    onChange={onFileInputChangePrediction}
                                                    ref={fileInputRefPrediction}
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    id="upload-predction"
                                                    multiple
                                                />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br/>
                {!!resultPredction.length && resultPredction.map((prediction, index) => (
                    <Row key={index}>
                        <Col>
                            <img style={{ width: '700px' }} src={imagesPrd[index]} />
                        </Col>
                        <Col>
                            <ReactJson src={prediction} />
                        </Col>
                    </Row>
                ))}
                <br/>
                <Row>
                    <Col>
                        <h3>Extração</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ marginBottom: '.5rem' }}><span>Arquivo para extração</span></div>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <div>
                                            <label htmlFor="upload">
                                                <Link>
                                                    <FontAwesomeIcon icon={faFileAlt} /> &nbsp;Selecione um arquivo...
                                                </Link>
                                                <input
                                                    onChange={onFileInputChange}
                                                    ref={fileInputRef}
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    id="upload"
                                                    multiple
                                                />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Form>
                            <Form.Group controlId="formBasicTemplateType">
                                <Form.Label>Tipo do Template</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="templateType"
                                    value={templateType}
                                    onChange={handleChange}
                                >
                                    {templateTypes.map((option, index) => (<option key={index} value={option.value}>{option.label}</option>))}    
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <br/>
                {!!result.length && result.map((json, index) => (
                    <Row key={index}>
                        <Col>
                            <img style={{ width: '700px' }} src={images[index]} />
                        </Col>
                        <Col>
                            <ReactJson src={json} />
                        </Col>
                    </Row>
                ))}
            </div>
        </React.Fragment>
    )
}

export default Simulation