import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Word } from '../../models/word';
import { Validate } from '../../models/validate';
import { SelectedWord } from '../../models/selectedWord';
import {
    isAlreadyOnRules,
    resetModal,
    getWord,
    isEqualVertices,
    areVerticesSomehowAlignedOnYAxis,
    areVerticesSomehowAlignedOnXAxis,
    hasLeftWord, extractVerticesRules,
    isEqualVerticesRules,
    isEqualVerticesTopDelimiter
} from './utils';
import { RIGHT, LEFT, defaultForm, defaultValidate, typeOptions, TOP } from './constants';
import { ExtractModalParams } from './interfaces';
import DelimitersCard from './components/DelimitersCard';
import { Rule } from '../../models/rule';
import { Vertice } from '../../models/vertice';
import alert from '../../components/common/alert';

const ExtractModal = ({ setCloseExtractModal, words, position, rules, onChangeRules, closeExtractModal } : ExtractModalParams): JSX.Element => {
    const [selectedWord, setSelectedWord] = useState({} as Word)
    const [selectedWords, setSelectedWords] = useState([] as SelectedWord[])
    const [wordValue, setWordValue] = useState('')
    const [rightWordsValues, setRightWordsValues] = useState([''])
    const [wordsFromEachLineForTopDelimiter, setTopWordsFromEachLine] = useState([] as Word[][])
    const [leftWordsValues, setLeftWordsValues] = useState([''])
    const [rightWords, setRightWords] = useState([] as Word[])
    const [leftWords, setLeftWords] = useState([] as Word[])
    const [addRange, setAddRange] = useState(false)
    const [leftRegex, setLeftRegex] = useState('');
    const [rightRegex, setRightRegex] = useState('');
    const [topRegex, setTopRegex] = useState('');

    const [form, setForm] = useState(defaultForm)

    const [validate, setValidate] = useState(defaultValidate)

    useEffect(() => {
        getSelectedWord()
    }, [position])

    useEffect(() => {
        if(selectedWord?.boundingBox) getWordsAround();
    }, [selectedWord, closeExtractModal])

    const getSelectedWord = () => {
        const selectedWord = words.find((word: Word) => {
            const maxX = word.boundingBox.vertices[1].x;
            const minX = word.boundingBox.vertices[0].x;

            const maxY = word.boundingBox.vertices[2].y;
            const minY = word.boundingBox.vertices[0].y;
           
            return  (position.x > minX && position.x < maxX) && (position.y > minY && position.y < maxY)
        }) as Word;

        if (!selectedWord) {
            resetModal(
                setForm,
                setCloseExtractModal,
                setValidate,
                setSelectedWords,
                leftWords,
                rightWords,
                selectedWord
            )
        } else {
            const { isARightOrLeftWord, word } = isAlreadyOnRules(rules, selectedWord)
            if (isARightOrLeftWord) {
                const newSelectedWord = words.find((w: Word) => isEqualVertices(w.boundingBox.vertices, word?.centerWord.vertices)) || selectedWord
                // const newSelectedWord = words.find((w: Word) => {
                //     const { vertices1, vertices2, word1, word2 } = extractVerticesHistoryWord(w, word)
                //     return isEqualVertices(vertices1, vertices2, word1, word2)
                // }) || selectedWord
                const wordValue = getWord(newSelectedWord)
    
                setWordValue(wordValue)
                setSelectedWord(newSelectedWord)
            } else {
                const wordValue = getWord(selectedWord)
    
                setWordValue(wordValue)
                setSelectedWord(selectedWord)
            }
        }
    }

    const getWordsAround = () => {
        const leftX = selectedWord.boundingBox.vertices[0].x;
        const rightX = selectedWord.boundingBox.vertices[1].x;

        // const rule = rules.find((r: Rule) => isEqualVertices(r.content.center.vertices, selectedWord.boundingBox.vertices))
        const rule = rules.find((r: Rule) => {
            const { vertices1, vertices2, word } = extractVerticesRules(r, selectedWord)
            return isEqualVerticesRules(vertices1, vertices2, word, selectedWord)
        })
        
        const sortedWords = [...words].sort((a, b) => a.boundingBox.vertices[0].x - b.boundingBox.vertices[0].x)

        const rightWords = sortedWords.filter(w => (w.boundingBox.vertices[0].x > rightX && areVerticesSomehowAlignedOnYAxis(w, selectedWord)))
        const leftWords = sortedWords.filter(w => w.boundingBox.vertices[1].x < leftX && areVerticesSomehowAlignedOnYAxis(w, selectedWord))

        const rightWordsValues = rightWords.map(word => getWord(word))
        const leftWordsValues = leftWords.map(word => getWord(word))

        setRightWordsValues(rightWordsValues)
        setLeftWordsValues(leftWordsValues)

        setRightWords(rightWords)
        setLeftWords(leftWords)

        const upperAndAlignedWords = words.filter(w => w.boundingBox.vertices[0].y < selectedWord.boundingBox.vertices[0].y && areVerticesSomehowAlignedOnXAxis(w, selectedWord)).reverse();
        const firstAlignedWordsFromEachLine = upperAndAlignedWords.filter(w => !hasLeftWord(w, upperAndAlignedWords));
        const wordsFromEachLineForTopDelimiter = firstAlignedWordsFromEachLine.map(w => {
            let wordsFromThatLine = [w];
            wordsFromThatLine = wordsFromThatLine.concat(upperAndAlignedWords.filter(w2 => w2.boundingBox.vertices[0].x > w.boundingBox.vertices[0].x && areVerticesSomehowAlignedOnYAxis(w2, w))
                                                            .sort((a, b) => a.boundingBox.vertices[0].x - b.boundingBox.vertices[0].x));
            return wordsFromThatLine;
        });
        setTopWordsFromEachLine(wordsFromEachLineForTopDelimiter);
        

        if (rule) {
            setForm({
                name: rule.name,
                type: rule.type,
                json: rule.json,
                groupFromThisRule: rule.groupFromThisRule
            });
            setLeftRegex(rule.delimiterLeft.regex || '');
            setRightRegex(rule.delimiterRight.regex || '');
            setTopRegex(rule.delimiterTop.regex || '');
            setSelectedWords([
                ...leftWords.map((word, index) => ({
                    word,
                    index,
                    position: LEFT,
                    selected: rule.content.left.some(l => {
                        const r = {
                            content : {
                                vertices: l.vertices
                            }
                        } as Rule;
                        const { vertices1, vertices2, word: w } = extractVerticesRules(r, word)
                        return isEqualVerticesRules(vertices1, vertices2, w, word)
                    }),
                    // isDelimiter: rule.delimiterLeft.words.some(({ vertices }) => isEqualVertices(vertices, word.boundingBox.vertices))
                    isDelimiter: rule.delimiterLeft.words.some(dl => {
                        const r = {
                            content : {
                                vertices: dl.vertices
                            }
                        } as Rule;
                        const { vertices1, vertices2, word: w } = extractVerticesRules(r, word)
                        return isEqualVerticesRules(vertices1, vertices2, w, word)
                    })
                })),
                {
                    word: selectedWord,
                    index: 0,
                    position: 'CENTER',
                    selected: true,
                    isDelimiter: false
                },
                ...rightWords.map((word, index) => ({
                    word,
                    index,
                    position: RIGHT,
                    selected: rule.content.right.some(l => {
                        const r = {
                            content : {
                                vertices: l.vertices
                            }
                        } as Rule;
                        const { vertices1, vertices2, word: w } = extractVerticesRules(r, word)
                        return isEqualVerticesRules(vertices1, vertices2, w, word)
                    }),
                    // isDelimiter: rule.delimiterRight.words.some(({ vertices }) => isEqualVertices(vertices, word.boundingBox.vertices))
                    isDelimiter: rule.delimiterRight.words.some(dr => {
                        const r = {
                            content : {
                                vertices: dr.vertices
                            }
                        } as Rule;
                        const { vertices1, vertices2, word: w } = extractVerticesRules(r, word)
                        return isEqualVerticesRules(vertices1, vertices2, w, word)
                    })
                })),
                ...wordsFromEachLineForTopDelimiter.flatMap(w => w).map((word, index) => ({
                    word,
                    index,
                    position: TOP,
                    selected: false,
                    // isDelimiter: rule.delimiterTop.words.some(({ vertices }) => isEqualVertices(vertices, word.boundingBox.vertices))
                    isDelimiter: rule.delimiterTop.words.some(dt => {
                        const r = {
                            boundingBox: {
                                vertices: dt.vertices
                            },
                            symbols: []
                        } as Word;
                        return isEqualVerticesTopDelimiter(r, word)
                    })
                }))
            ])
            setAddRange(true)
        } else {
            setForm(defaultForm)
            setSelectedWords([
                ...leftWords.map((word, index) => ({ word, index, position: LEFT, selected: false, isDelimiter: false })),
                {
                    word: selectedWord,
                    index: 0,
                    position: 'CENTER',
                    selected: true,
                    isDelimiter: false 
                },
                ...rightWords.map((word, index) => ({ word, index, position: RIGHT, selected: false, isDelimiter: false })),
                ...wordsFromEachLineForTopDelimiter.flatMap(w => w).map((word, index) => ({
                    word,
                    index,
                    position: TOP,
                    selected: false,
                    isDelimiter: false
                }))
            ])
            setAddRange(false)
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValidate({
            ...validate,
            [event.target.name]: {
                message: '',
                ok: true
            }
        })
        setForm({...form, [event.target.name]: event.target.value})
    }

    const isValid = () => {
        const validations = {
            name: {
               message: form.name ? '' : 'Esse campo é obrigatótio',
               ok: form.name ? true : false
            },
            type: {
                message: form.type ? '' : 'Esse campo é obrigatótio',
                ok: form.type ? true : false 
            },
            json: {
                message: form.json ? '' : 'Esse campo é obrigatótio',
                ok: form.json ? true : false
            }
        } as Validate
        setValidate(validations)

        return Object.keys(validations).every(key => validations[key as keyof Validate].ok)
    }

    const getContentVertices = (): Vertice[] => {
        const rightWords = selectedWords.filter(word => word.position === RIGHT && word.selected)
        const leftWords = selectedWords.filter(word => word.position === LEFT && word.selected)
        const centerWord = selectedWords.find(word => word.position === 'CENTER')

        const lastWord = rightWords[rightWords.length - 1] || centerWord
        const firstWord = leftWords[0] || centerWord

        const x0 = firstWord.word.boundingBox.vertices[0].x
        const x1 = lastWord.word.boundingBox.vertices[1].x
        const y0 = firstWord.word.boundingBox.vertices[0].y
        const y1 = firstWord.word.boundingBox.vertices[2].y

        return [
            { x: x0, y: y0 },
            { x: x1, y: y0 },
            { x: x1, y: y1 },
            { x: x0, y: y1 }
        ]
    }

    const getDelimitersVertices = (position: string) => {
        const words = selectedWords.filter(word => word.position === position && word.isDelimiter)
 
        const lastWord = words[words.length - 1]
        const firstWord = words[0]

        if (lastWord && firstWord) {
            const x0 = firstWord.word.boundingBox.vertices[0].x
            const x1 = lastWord.word.boundingBox.vertices[1].x
            const y0 = firstWord.word.boundingBox.vertices[0].y
            const y1 = firstWord.word.boundingBox.vertices[2].y
    
            return [
                { x: x0, y: y0 },
                { x: x1, y: y0 },
                { x: x1, y: y1 },
                { x: x0, y: y1 }
            ]
        }

        return []
    }

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (isValid()) {
            const rule = {
                delimiterRight: addRange
                                ? {
                                    vertices: getDelimitersVertices(RIGHT),
                                    value: selectedWords.filter(word => word.position === RIGHT && word.isDelimiter).reduce((acc, curr) => `${acc} ${getWord(curr.word)}`, '').trim() || '',
                                    regex: rightRegex,
                                    words: selectedWords.filter(word => word.position === RIGHT && word.isDelimiter).map(word => ({
                                                vertices: word.word.boundingBox.vertices,
                                                value: getWord(word.word)
                                            })) || []
                                }
                                : {
                                    vertices: [],
                                    value: '',
                                    words: []
                                },
                delimiterLeft: addRange
                                ? {
                                    vertices: getDelimitersVertices(LEFT),
                                    value: selectedWords.filter(word => word.position === LEFT && word.isDelimiter).reduce((acc, curr) => `${acc} ${getWord(curr.word)}`, '').trim(),
                                    regex: leftRegex,
                                    words: selectedWords.filter(word => word.position === LEFT && word.isDelimiter).map(word => ({
                                                vertices: word.word.boundingBox.vertices,
                                                value: getWord(word.word)
                                            }))
                                }
                                : {
                                    vertices: [],
                                    value: '',
                                    words: []
                                },
                delimiterTop: addRange
                                ? {
                                    vertices: getDelimitersVertices(TOP),
                                    value: selectedWords.filter(word => word.position === TOP && word.isDelimiter).reduce((acc, curr) => `${acc} ${getWord(curr.word)}`, '').trim(),
                                    regex: topRegex,
                                    words: selectedWords.filter(word => word.position === TOP && word.isDelimiter).map(word => ({
                                                vertices: word.word.boundingBox.vertices,
                                                value: getWord(word.word)
                                            }))
                                }
                                : {
                                    vertices: [],
                                    value: '',
                                    words: []
                                },
                content: {
                    vertices: getContentVertices(),
                    value: selectedWords.filter(word => word.selected).reduce((acc, curr) => `${acc} ${getWord(curr.word)}`, '').trim(),
                    center: {
                        vertices: selectedWords.find(word => word.position === 'CENTER')?.word.boundingBox.vertices || [],
                        value: getWord(selectedWords.find(word => word.position === 'CENTER')?.word)
                    },
                    right: addRange
                            ? selectedWords.filter(word => word.position === RIGHT && word.selected).map(word => ({
                                vertices: word.word.boundingBox.vertices,
                                value: getWord(word.word)
                            }))
                            : [],
                    left: addRange
                            ? selectedWords.filter(word => word.position === LEFT && word.selected).map(word => ({
                                vertices: word.word.boundingBox.vertices,
                                value: getWord(word.word)
                            }))
                            : [],
                    top: addRange
                            ? selectedWords.filter(word => word.position === TOP && word.isDelimiter).map(word => ({
                                vertices: word.word.boundingBox.vertices,
                                value: getWord(word.word)
                            }))
                            : [],
                },
                name: form.name,
                type: form.type,
                json: form.json,
                groupFromThisRule: form.groupFromThisRule
            }
            
            if (form.groupFromThisRule === true) {
                rules.map(r => r.groupFromThisRule = false);
            }

            const ruleExists = rules.some((r: Rule) => isEqualVertices(r.content.center.vertices, rule.content.center.vertices))
            if (ruleExists) {
                const updatedRules = rules.map((r: Rule) => {
                    if (isEqualVertices(r.content.center.vertices, rule.content.center.vertices)) return rule
                    return r
                })
                onChangeRules(updatedRules)
            } else {
                onChangeRules([...rules, rule])
            }

            resetModal(
                setForm,
                setCloseExtractModal,
                setValidate,
                setSelectedWords,
                leftWords,
                rightWords,
                selectedWord
            )
        }
    }

    const onDeleteRule = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const result = await alert({
            title: 'Deletar regra?',
            text: 'A regra será deletada permanetemente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar'
        })

        if(result.isConfirmed) deleteRule()
    }

    const deleteRule = (): void => {
        const rule = rules.find(r => isEqualVertices(r.content.center.vertices, selectedWord.boundingBox.vertices))
        const updatedRules = rules.filter((r: Rule) => !isEqualVertices(r.content.center.vertices, rule?.content.center.vertices))
        onChangeRules(updatedRules)

        resetModal(
            setForm,
            setCloseExtractModal,
            setValidate,
            setSelectedWords,
            leftWords,
            rightWords,
            selectedWord
        )
    }

    const getWordValue = () => {
        return selectedWords.filter(word => word.selected).reduce((acc, curr) => `${acc} ${getWord(curr.word)}`, '').trim()
    }

    return (
        <Card style={{
            boxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)',
            height: 'calc(100vh - 57px - 90px)'
        }}>
            <Card.Body>
                <Row>
                    <Col onClick={() => {
                            resetModal(
                                setForm,
                                setCloseExtractModal,
                                setValidate,
                                setSelectedWords,
                                leftWords,
                                rightWords,
                                selectedWord
                            )
                        }}
                        style={{ cursor: 'pointer', textAlign: 'end' }} xs={12}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </Col>
                    <Col xs={12}><h5>Selecione as propriedades do valor: <strong>{getWordValue()}</strong></h5><hr/></Col>
                    <Col xs={12}>
                        <div style={{ height: 'calc(100vh - 57px - 230px)', overflowY: 'scroll' }}>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nome do Campo</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        placeholder="Nome do Campo"
                                        value={form.name}
                                        onChange={handleChange}
                                        isInvalid={!validate.name.ok}
                                    />
                                    <Form.Control.Feedback type="invalid">{validate.name.message}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formJson">
                                    <Form.Label>Chave JSON</Form.Label>
                                    <Form.Control
                                        name="json"
                                        type="text"
                                        placeholder="Chave JSON"
                                        value={form.json}
                                        onChange={handleChange}
                                        isInvalid={!validate.json.ok}
                                    />
                                    <Form.Control.Feedback type="invalid">{validate.json.message}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formType">
                                    <Form.Label>Tipo do Campo</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="type"
                                        value={form.type}
                                        onChange={handleChange}
                                        isInvalid={!validate.type.ok}
                                    >
                                        {typeOptions.map((option, index) => (<option key={index} value={option.value}>{option.label}</option>))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{validate.type.message}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check 
                                        name="groupFromThisRule" 
                                        
                                        type="checkbox" 
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setForm({...form, [event.target.name]: event.target.checked})
                                        }} 
                                        checked={form.groupFromThisRule} 
                                        label="Agrupar a partir desta regra" />
                                </Form.Group>
                                <DelimitersCard
                                    setSelectedWords={setSelectedWords}
                                    setAddRange={setAddRange}
                                    setLeftRegex={setLeftRegex}
                                    setRightRegex={setRightRegex}
                                    setTopRegex={setTopRegex}
                                    leftWordsValues={leftWordsValues}
                                    rightWordsValues={rightWordsValues}
                                    wordsFromEachLineForTopDelimiter={wordsFromEachLineForTopDelimiter}
                                    addRange={addRange}
                                    selectedWords={selectedWords}
                                    wordValue={wordValue}
                                    leftRegex={leftRegex}
                                    rightRegex={rightRegex}
                                    topRegex={topRegex}
                                />
                                <Button className="btn-primary" type="submit">
                                    Salvar
                                </Button>
                                <Button onClick={(event) => {
                                        event.preventDefault()
                                        resetModal(
                                            setForm,
                                            setCloseExtractModal,
                                            setValidate,
                                            setSelectedWords,
                                            leftWords,
                                            rightWords,
                                            selectedWord
                                        )
                                    }}
                                    className="btn-secondary" type="submit"
                                    style={{ marginLeft: '0.5rem' }}
                                >
                                    Cancelar
                                </Button>
                                <Button onClick={onDeleteRule} className="btn-danger" type="submit" style={{ marginLeft: '2rem' }}>
                                    Deletar
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ExtractModal;