import React from 'react'
import { Breadcrumb } from 'react-bootstrap';
import { CustomBreadcrumbInt } from '../../../models/customBreadcrumbInt';

interface CustomBreadcrumbParams {
    breadcrumbs: CustomBreadcrumbInt[]
}

const CustomBreadcrumb = ({ breadcrumbs }: CustomBreadcrumbParams): JSX.Element => {
    return (
        <Breadcrumb>
            {breadcrumbs.map(breadcrumb => (
                <Breadcrumb.Item key={breadcrumb.path} href={breadcrumb.path}>{breadcrumb.value}</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    )
}

export default CustomBreadcrumb;