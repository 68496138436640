import React from 'react'
import styled from 'styled-components'
import { faHome, faUser, faTable, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Link = styled.a`
    cursor: pointer;
`;
interface SidebarPros {
    isVisible: boolean,
    setIsSidebarVisible(value: boolean): void,
    windowDimensions: number,
    onRedirect(value: string): void
}

const Sidebar = ({ onRedirect, setIsSidebarVisible, isVisible, windowDimensions }: SidebarPros): JSX.Element | null => {
    const handleRedirect = (path: string) => {
        onRedirect(path)
        if(windowDimensions <= 768) setIsSidebarVisible(false)
    }
    return (
        isVisible ?
        <div className="navbar-default sidebar bg-light" role="navigation">
            <div className="sidebar-nav navbar-collapse collapse">
                <ul id="side-menu">
                    <li>
                        <Link onClick={() => handleRedirect('/')}>
                            <FontAwesomeIcon icon={faHome} /> &nbsp;Home
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => handleRedirect('/users')}>
                            <FontAwesomeIcon icon={faUser} /> &nbsp;Usuários
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => handleRedirect('/templates')}>
                            <FontAwesomeIcon icon={faTable} /> &nbsp;Templates
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => handleRedirect('/simulation')}>
                            <FontAwesomeIcon icon={faFileAlt} /> &nbsp;Simulação
                        </Link>
                    </li>
                </ul>
            </div>
       </div> : null
    )
}

export default Sidebar