import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BaseProps from '../../models/baseProps';
import UserDetail from './UserDetail';
import UserForm from './UserForm';
import UserList from './UserList';

interface UserProps extends BaseProps{
    setRedirectTo(path: string): void
}

const User = ({ history, redirectTo, setRedirectTo }: UserProps): JSX.Element => {
    useEffect(() => {
        setRedirectTo('')
        if(redirectTo) history.push(redirectTo)
    }, [redirectTo]);

    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}/add`}
                render={props => <UserForm {...props} />}
            />
            <Route
                exact
                path={`${path}/:id/edit`}
                render={props => <UserForm {...props} />}
            />
            <Route component={UserDetail} path={`${path}/:id/detail`} />
            <Route
                exact
                path={path}
                render={props => <UserList {...props} />}
            />
        </Switch>
    )
}

export default User;