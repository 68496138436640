import React from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';
import Dashboard from '../../pages/Dashboard';
import Logs from '../../pages/Logs';
import Simulation from '../../pages/Simulation';
import Template from '../../pages/template/Template';
import User from '../../pages/user/User'

interface ContentProps {
    redirectTo: string
    setRedirectTo(path: string): void
}

const Content = ({ setRedirectTo, redirectTo }: ContentProps): JSX.Element => {
    return (
        <React.Fragment>
            <div id="page-wrapper" className="page-wrapper">
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={props => <Dashboard {...props} setRedirectTo={setRedirectTo} redirectTo={redirectTo} />}
                    />
                    <Route
                        exact
                        path="/simulation"
                        render={props => <Simulation {...props} setRedirectTo={setRedirectTo} redirectTo={redirectTo} />}
                    />
                    <Route
                        exact
                        path="/logs/:id/flow/:flow/requestId/:requestId"
                        render={props => <Logs {...props} setRedirectTo={setRedirectTo} redirectTo={redirectTo} />}
                    />
                    <Route
                        path="/users"
                        render={props => <User {...props} setRedirectTo={setRedirectTo} redirectTo={redirectTo} />}
                    />
                    <Route
                        path="/templates"
                        render={props => <Template {...props} setRedirectTo={setRedirectTo} redirectTo={redirectTo} />}
                    />
                    <Route render={() => <h1>Page not found</h1>} />
                </Switch>
            </div>
        </React.Fragment>
    )
}

export default Content