import { FormInteface } from "../../models/form";
import { Rule } from "../../models/rule";
import { SelectedWord } from "../../models/selectedWord";
import { Validate } from "../../models/validate";
import { Vertice } from "../../models/vertice";
import { Word } from "../../models/word"
import { RIGHT, LEFT, defaultForm, defaultValidate } from './constants';
import { RuleHistory } from "./interfaces";

const TRESHOLD = 20;

export const getWord = (word: Word | undefined): string => {
    if (!word) return '';
    return word && word.symbols && word.symbols.reduce((acc, curr) => `${acc}${curr.text}`, '')
}

export const isAlreadyOnRules = (rules: Rule[], selectedWord: Word): RuleHistory => {
    const rulesRightWords = rules.flatMap((r: Rule) => r.content.right.flatMap(({ vertices }) => ({ vertices, centerWord: r.content.center })))
    const rulesLeftWords = rules.flatMap((r: Rule) => r.content.left.flatMap(({ vertices }) => ({ vertices, centerWord: r.content.center })))
    const rulesCenterWords = rules.map((r: Rule) => ({ vertices: r.content.center.vertices, centerWord: r.content.center }))
    const isARightOrLeftWord = rulesRightWords.some(({ vertices }) => isEqualVertices(vertices, selectedWord.boundingBox.vertices) || rulesLeftWords.some(({ vertices }) => isEqualVertices(vertices, selectedWord.boundingBox.vertices)))
    const isACenterWord = rulesCenterWords.some(({ vertices }) => isEqualVertices(vertices, selectedWord.boundingBox.vertices))
    
    const word = rulesRightWords.find(({ vertices }) =>
        isEqualVertices(vertices, selectedWord.boundingBox.vertices))
        || rulesLeftWords.find(({ vertices }) => isEqualVertices(vertices, selectedWord.boundingBox.vertices))
        || rulesCenterWords.find(({ vertices }) => isEqualVertices(vertices, selectedWord.boundingBox.vertices))

    return { isARightOrLeftWord, isACenterWord, word }
}

interface ExtractedVertices {
    vertices1: Vertice[],
    vertices2: Vertice[],
    word: Word
}

export const extractVerticesRules = (rule: Rule, selectedWord: Word): ExtractedVertices => {
    const vertices1 = rule.content.vertices
    const vertices2 = selectedWord.boundingBox.vertices
    const word = {
        boundingBox: {
            vertices: rule.content.vertices
        },
        symbols: []
    }

    return { vertices1, vertices2, word } 
}

export const isEqualVerticesRules = (vertices1: Vertice[] | undefined, vertices2: Vertice[] | undefined, word: Word, selectedWord: Word): boolean => {
    if (!vertices1 || !vertices2 || !vertices1.length || !vertices2.length) return false
    return Math.abs(vertices1[0].x - vertices2[0].x) <= TRESHOLD
            && vertices1[1].x >= (vertices2[1].x - TRESHOLD)
            && vertices1[2].x >= (vertices2[2].x - TRESHOLD)
            && Math.abs(vertices1[3].x - vertices2[3].x) <= TRESHOLD
            && areVerticesSomehowAlignedOnYAxis(word, selectedWord)
    // return vertices1[0].x <= vertices2[0].x
    //         && vertices1[1].x >= vertices2[1].x
    //         && vertices1[2].x >= vertices2[2].x
    //         && vertices1[3].x <= vertices2[3].x
    //         && areVerticesSomehowAlignedOnYAxis(word, selectedWord)
}

export const isEqualVerticesTopDelimiter = (word: Word, selectedWord: Word): boolean => {
    return areVerticesSomehowAlignedOnXAxis(word, selectedWord) && areVerticesSomehowAlignedOnYAxis(word, selectedWord)
}

export const isEqualVertices = (vertices1: Vertice[] | undefined, vertices2: Vertice[] | undefined): boolean => {
    if (!vertices1 || !vertices2 || !vertices1.length || !vertices2.length) return false
    return (Math.abs(vertices1[0].x - vertices2[0].x) <= TRESHOLD
            && Math.abs(vertices1[0].y - vertices2[0].y) <= TRESHOLD) ||
            (Math.abs(vertices1[1].x - vertices2[1].x) <= TRESHOLD
            && Math.abs(vertices1[1].y - vertices2[1].y) <= TRESHOLD) ||
            (Math.abs(vertices1[2].x - vertices2[2].x) <= TRESHOLD
            && Math.abs(vertices1[2].y - vertices2[2].y) <= TRESHOLD) ||
            (Math.abs(vertices1[3].x - vertices2[3].x) <= TRESHOLD
            && Math.abs(vertices1[3].y - vertices2[3].y) <= TRESHOLD)
}

export const resetModal = (
    setForm: (value: FormInteface) => void,
    setCloseExtractModal: (value: boolean) => void,
    setValidate: (value: Validate) => void,
    setSelectedWords: (value: SelectedWord[]) => void,
    leftWords: Word[],
    rightWords: Word[],
    selectedWord: Word
): void  => {
    setForm(defaultForm)
    setCloseExtractModal(true)
    setValidate(defaultValidate)
    setSelectedWords([
        ...leftWords.map((word: Word, index: number) => ({ word, index, position: LEFT, selected: false, isDelimiter: false  })),
        {
            word: selectedWord,
            index: 0,
            position: 'CENTER',
            selected: true,
            isDelimiter: false 
        },
        ...rightWords.map((word: Word, index: number) => ({ word, index, position: RIGHT, selected: false, isDelimiter: false }))
    ])
}

export const areVerticesSomehowAlignedOnYAxis = (
    word: Word,
    selectedWord: Word
): boolean => {
    const leftTopVerticeY = selectedWord.boundingBox.vertices[0].y;
    const leftBottomVerticeY = selectedWord.boundingBox.vertices[3].y;
    const targetMidpoint = (leftTopVerticeY + leftBottomVerticeY) / 2;
    const targetHeight = leftBottomVerticeY - leftTopVerticeY;
    const wordHeight = word.boundingBox.vertices[3].y - word.boundingBox.vertices[0].y;
    const wordMidpoint = (word.boundingBox.vertices[0].y + word.boundingBox.vertices[3].y) / 2;
    
    if (targetHeight <= wordHeight) {
        return targetMidpoint >= word.boundingBox.vertices[0].y && targetMidpoint <= word.boundingBox.vertices[3].y;
    }
    return wordMidpoint >= leftTopVerticeY && wordMidpoint <= leftBottomVerticeY;
}

export const areVerticesSomehowAlignedOnXAxis = (word: Word, target: Word): boolean => {
    const midpointX = (word.boundingBox.vertices[0].x + word.boundingBox.vertices[1].x) / 2;
    const midpointTargetX = (target.boundingBox.vertices[0].x + target.boundingBox.vertices[1].x) / 2;
    return (midpointX >= target.boundingBox.vertices[0].x && midpointX <= target.boundingBox.vertices[1].x) || 
    (word.boundingBox.vertices[0].x >= target.boundingBox.vertices[0].x && word.boundingBox.vertices[0].x <= target.boundingBox.vertices[1].x) ||
    (word.boundingBox.vertices[1].x >= target.boundingBox.vertices[0].x && word.boundingBox.vertices[1].x <= target.boundingBox.vertices[1].x) ||
    (midpointTargetX <= word.boundingBox.vertices[1].x && midpointTargetX >= word.boundingBox.vertices[0].x);

  }

export const hasLeftWord = (wordToBeCompared: Word, otherWords: Word[]): boolean => {
    const midpoint = (wordToBeCompared.boundingBox.vertices[0].y + wordToBeCompared.boundingBox.vertices[3].y) / 2;
    return otherWords.some(w => {
        return w.boundingBox.vertices[0].x < wordToBeCompared.boundingBox.vertices[0].x &&
            (midpoint >= w.boundingBox.vertices[0].y && midpoint <= w.boundingBox.vertices[3].y)
    });
}