import React, { useState, useEffect, useRef, MutableRefObject, RefObject } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { Row, Col, Button, Form, Badge, Image } from 'react-bootstrap';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { faTimes, faPlusCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileDrop } from 'react-file-drop';
import { RouteComponentProps } from 'react-router-dom';
import uploadfileIcon from '../../assets/file.png';
import alert from '../../components/common/alert';
import { Validate } from '../../models/validate';
import { TemplateType } from '../../models/templateType';
import { Files } from '../../models/files';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';
import ReactLoading from 'react-loading';
import { appFetch, storageFetch } from '../../services/fetch';
import { Template } from '../../models/template';
import Url from '../../models/url';

const Link = styled.a`
    color: #000000;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const tmpFolder = `tmp-${uuid()}`;

type TParams = { id: string };

const extensionsAllowed = ['png', 'jpg', 'jpeg', 'pdf', 'zip', 'rar', 'PNG', 'JPG', 'JPEG', 'PDF', 'ZIP', 'RAR'];

interface Src {
    url: string,
    path: string
}

const TemplateForm = ({ match, history }: RouteComponentProps<TParams>): JSX.Element => {
    const [template, setTemplate] = useState({
        name: '',
        type: {
            name: '',
            description: ''
        } as TemplateType,
        files: {
            folder: '',
            defaultFile: ''
        } as Files
    });

    const [files, setFiles] = useState([] as string[]);
    const [deletedFiles, setDeletedFiles] = useState([] as string[]);
    const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const [srcs, setSrcs] = useState([] as Src[]);

    const [templateTypeOptions, setTemplateTypeOptions] = useState([] as TemplateType[]);
    const [selectedTemplateType, setSelectedTemplateType] = useState({
        name: '',
        description: ''
    } as TemplateType);
    const [isLoading, setIsLoading] = useState(false);
    const templateTypeInputRef = useRef() as RefObject<AsyncTypeahead<TemplateType>>

    const { id } = match.params;
    const action = id ? 'Editar' : 'Cadastrar'

    const breadcrumb = {
        path: id ? `/templates/${id}/edit` : '/templates/add',
        value: id ? 'Editar Tamplate' : 'Cadastrar Template'
    }

    const breadcrumbs = [
        {
            path: '/',
            value: 'Home'
        },
        {
            path: '/templates',
            value: 'Templates'
        },
        breadcrumb
    ]

    useEffect(() => {
        if (id) getTemplate(id);
    }, [])

    useEffect(() => {
        setForm(template)
    }, [template])

    const getTemplate = async (id: string) => {
        setIsLoading(true)

        const template = await appFetch(`template/${id}`, 'GET') as Template;
        const { paths } = await appFetch(`utils/files/${id}`, 'GET') as Url;

        const files = paths || []
        const urls = await Promise.all(files.map(async path =>await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url))
        const blobResponses = await Promise.all(urls.map(async ({ signedURL }) => await storageFetch(signedURL || '', 'GET') as Response))
        const blobs = await Promise.all(blobResponses.map(async response => await response?.blob()))
        const srcs = await Promise.all(blobs.map((blob, index) => ({ url: URL.createObjectURL(blob), path: files[index]})))
        
        setSrcs(srcs)
        setFiles(paths || [])
        setSelectedTemplateType(template.type);
        setTemplate(template)
        setIsLoading(false)
    }

    const [form, setForm] = useState({
        name: template.name,
        type: template.type,
        files: template.files
    })

    const [validate, setValidate] = useState({
        name: {
            message: '',
            ok: true
        },
        type: {
            message: '',
            ok: true
        },
        files: {
            message: '',
            ok: true
        }
    } as Validate)

    const isValid = () => {
        // TODO regex type
        const validations = {
            name: {
               message: form.name ? '' : 'Esse campo é obrigatótio',
               ok: form.name ? true : false
            },
            type: {
                message: form.type.name ? '' : 'Esse campo é obrigatótio',
                ok: form.type.name ? true : false 
            },
            files: {
                message: files.length ? '' : 'Esse campo é obrigatótio',
                ok: files.length ? true : false
            }
        } as Validate
        setValidate(validations)

        return Object.keys(validations).every(key => validations[key as keyof Validate].ok)
    }

    const createTemplate = async () => {
        if(isValid()) {
            setIsLoading(true)
            try {
                await appFetch('utils/deleteFilesFromStorage', 'POST', {}, { paths: deletedFiles })
                await appFetch('template', 'POST', {}, { template: {...form, files: { folder: tmpFolder }}, tmpFolder })
                setIsLoading(false)
                await alert({
                    text: 'Template criado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                })
                history.push('/templates')
            } catch (error) {
                setIsLoading(false)
                await alert({
                    text: 'Não foi possível criar o template',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const updateTemplate = async (id: string) => {
        if(isValid()) {
            setIsLoading(true)
            try {
                await appFetch('utils/deleteFilesFromStorage', 'POST', {}, { paths: deletedFiles })
                await appFetch(`template/${id}`, 'PUT', {}, { template: form, tmpFolder })
                setIsLoading(false)
                await alert({
                    text: 'Template salvo com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                })

                history.push('/templates')
            } catch (error) {
                setIsLoading(false)
                await alert({
                    text: 'Não foi possível salvar o template',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (id) {
            updateTemplate(id);
        } else {
            createTemplate();
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValidate({
            ...validate,
            [event.target.name]: {
                message: '',
                ok: true
            }
        })
        setTemplate({...template, [event.target.name]: event.target.value})
    }

    const readAsDataURL = (file: File) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = () => {
              resolve(fr.result);
            }
            fr.readAsDataURL(file);
        });
    };

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const handleFileInput = async (file: File) => {
        setIsLoading(true)
        const name = file.name
        const data = await readAsDataURL(file)

        const { paths } = await appFetch(`utils/uploadFilesToStorage?FileName=${name}&tmpFolder=${tmpFolder}`, 'POST', {}, { data }) as Url;
        return paths || [];
    }

    const hasInvalidExtensions = (files: FileList) => {
        return Array.from(files).some(file => {
            const extension = file.name.split('.').pop() || ''
            return !extensionsAllowed.includes(extension)
        })
    }

    const onDrop = async (droppedfiles: FileList | null) => {
        if (droppedfiles) {
            if(!hasInvalidExtensions(droppedfiles)) {
                try {
                    const inputFiles = await Promise.all(Array.from(droppedfiles).map(async (file) => await handleFileInput(file)))
                    const mappedInputFiles = inputFiles.flatMap(file => file)

                    const mappedFiles = mappedInputFiles || []
                    const urls = await Promise.all(mappedFiles.map(async path =>await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url))
                    const blobResponses = await Promise.all(urls.map(async ({ signedURL }) => await storageFetch(signedURL || '', 'GET') as Response))
                    const blobs = await Promise.all(blobResponses.map(async response => await response?.blob()))
                    const newSrcs = await Promise.all(blobs.map((blob, index) => ({ url: URL.createObjectURL(blob), path: mappedFiles[index]})))
                    
                    setSrcs([...srcs, ...newSrcs])
                    setValidate({
                        ...validate,
                        files: {
                            message: '',
                            ok: true
                        }
                    })
                    setFiles([...files, ...mappedInputFiles])
                    setIsLoading(false)
                } catch (error) {
                    setIsLoading(false)
                    await alert({
                        title: 'Não foi possével fazer o upload do arquivo',
                        text: 'Tente novamente',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    })
                }
            } else {
                await alert({
                    title: 'Arquivo inválido',
                    text: 'Só serão permitidos upload de arquivos nos formatos png, jpeg, pdf, zip ou rar',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const onFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files: inputedfiles } = event.target;
        if(inputedfiles) {
            if(!hasInvalidExtensions(inputedfiles)) {
                try {
                    const inputFiles = await Promise.all(Array.from(inputedfiles).map(async (file) => await handleFileInput(file)))
                    const mappedInputFiles = inputFiles.flatMap(file => file)

                    const mappedFiles = mappedInputFiles || []
                    const urls = await Promise.all(mappedFiles.map(async path =>await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url))
                    const blobResponses = await Promise.all(urls.map(async ({ signedURL }) => await storageFetch(signedURL || '', 'GET') as Response))
                    const blobs = await Promise.all(blobResponses.map(async response => await response?.blob()))
                    const newSrcs = await Promise.all(blobs.map((blob, index) => ({ url: URL.createObjectURL(blob), path: mappedFiles[index]})))
                    
                    setSrcs([...srcs, ...newSrcs])
                    setValidate({
                        ...validate,
                        files: {
                            message: '',
                            ok: true
                        }
                    })
                    setFiles([...files, ...mappedInputFiles])
                    setIsLoading(false)
                } catch (error) {
                    setIsLoading(false)
                    await alert({
                        title: 'Não foi possével fazer o upload do arquivo',
                        text: 'Tente novamente',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    })
                }
            } else {
                await alert({
                    title: 'Arquivo inválido',
                    text: 'Só serão permitidos upload de arquivos nos formatos png, jpeg, pdf, zip ou rar',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const onDeleteFile = async (path: string) => {
        const result = await alert({
            title: 'Deletar arquivo?',
            text: 'O arquivo será deletado permanetemente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar'
        })

        if(result.isConfirmed) {
            setFiles([...files.filter((file) => file !== path)])
            setDeletedFiles([...deletedFiles, path])
            setSrcs([...srcs.filter((src) => src.path !== path)])
        }
    }

    const onViewFile = async (path: string) => {
        const { signedURL }  = await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url;
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = signedURL || '';
        link.click();
    }

    const handleSearch = async (input: string) => {
        const templateTypes = await appFetch(`template-type?name=${input}`, 'GET') as TemplateType[];
        setTemplateTypeOptions(templateTypes);
    }

    const onRemoveTemplateType = () => {
        setSelectedTemplateType({ name: '', description: '' })
        setTemplate({ ...template, type: { name: '', description: '' }})
    }
    const onAddTemplateType = async () => {
        const { current } = templateTypeInputRef
        const ref = current as unknown as Typeahead<TemplateType>
        const input = ref?.getInput();
        const name = input?.value as string || '';
        const inputOnOptions = templateTypeOptions.find(option => option.name === name)
        const selectedTemplateType = inputOnOptions || { name, description: '' }

        if (!selectedTemplateType._id && selectedTemplateType.name) {
            setIsLoading(true)
            const templateType = await appFetch('template-type', 'POST', {},  selectedTemplateType) as TemplateType;
            setSelectedTemplateType(templateType)
            setTemplate({ ...template, type: templateType })
        } else {
            setSelectedTemplateType(selectedTemplateType)
            setTemplate({ ...template, type: selectedTemplateType })
        }

        setIsLoading(false)
        ref?.clear()
        setValidate({
            ...validate,
            type: {
                message: '',
                ok: true
            }
        })
    }

    return (
        <React.Fragment>
            {isLoading && <div style={{ width: 'calc(100% - 251px)', height: 'calc(100vh - 57px)', position: 'absolute', zIndex: 1, backgroundColor: '#000', opacity: 0.2 }}>
                <ReactLoading className='spinner-margin' type={'spin'} color={'#007bff'} height={'250px'} width={'250px'} />
            </div>}
            <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
                <Row>
                    <Col><h1>{`${action} Template`}</h1></Col>
                    <Col>
                        <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                    </Col>
                </Row>
                <hr/>
                <br/>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="Nome..."
                                            value={form.name}
                                            onChange={handleChange}
                                            isInvalid={!validate.name.ok}
                                        />
                                        <Form.Control.Feedback type="invalid">{validate.name.message}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formBasicType">
                                        <Form.Label>Tipo</Form.Label>
                                        <Row>
                                            <Col>
                                                <AsyncTypeahead
                                                    ref={templateTypeInputRef}
                                                    labelKey={(option) => option.name}
                                                    options={templateTypeOptions}
                                                    placeholder="Pesquisar tipo..."
                                                    isInvalid={!validate.type.ok}
                                                    minLength={1}
                                                    onSearch={handleSearch}
                                                    isLoading={false}
                                                    id="typeahead"
                                                />
                                            </Col>
                                            <Col xs="auto" style={{ paddingLeft: '0px', display: 'flex', alignItems: 'center' }}>
                                                {!isLoading && <FontAwesomeIcon icon={faPlusCircle} style={{ cursor: 'pointer' }} onClick={onAddTemplateType}/>}
                                            </Col>
                                        </Row>
                                        {selectedTemplateType.name &&
                                            <h5 style={{ marginTop: '0.5rem' }}>
                                                <Badge pill variant="primary">
                                                    {selectedTemplateType.name}&nbsp;&nbsp;
                                                    <FontAwesomeIcon onClick={onRemoveTemplateType} style={{ cursor: 'pointer' }}icon={faTimes}/>
                                                </Badge>
                                            </h5>}
                                        {!selectedTemplateType.name && validate.type.ok && <span style={{ marginTop: '0.5rem', fontSize: '0.8rem' }}>Não há tipo selecionado...</span>}
                                        {!validate.type.ok && <span className="feedback-error">{validate.type.message}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            {!!files.length &&
                            <div>
                                <span>Arquivos</span>
                                <div style={{ height: '170px', overflowY: 'scroll', overflowX: 'hidden', marginBottom: '15px', marginTop: '10px' }}>
                                    <Row xs={1} md={6} style={{ margin: '0' }}>
                                        {!!files.length && files.map((path, index)  => (
                                            <Col key={path} style={{ textAlign: 'center', marginTop: '20px' }}>
                                                <div style={{ position: 'relative' }}>
                                                    <Image className='img-card-boder' src={srcs[index].url}></Image>
                                                    <FontAwesomeIcon
                                                        onClick={() => onViewFile(path)}
                                                        icon={faEye}
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            bottom: '10px',
                                                            color: '#007bff',
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faTimes}
                                                        onClick={() => onDeleteFile(path)}
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '10px',
                                                            color: '#007bff',
                                                            cursor: 'pointer'
                                                        }}/>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>}
                            <div style={{ border: '1px solid rgba(0,0,0,.125)', color: 'black', padding: 20, margin: 'auto', textAlign: 'center' }}>
                                <FileDrop
                                    onTargetClick={onTargetClick}
                                    onDrop={onDrop}
                                >
                                    Arraste e solte seus arquivos de template aqui...
                                    <br/>
                                    <img src={uploadfileIcon} style={{ width: '60px', margin: '1rem 0' }}/>
                                    <br/>
                                </FileDrop>
                                <label htmlFor="upload">
                                    <Link>ou clique aqui para selecionar um arquivo...</Link>
                                    <input
                                        onChange={onFileInputChange}
                                        ref={fileInputRef}
                                        type="file"
                                        style={{ display: 'none' }}
                                        id="upload"
                                        multiple
                                    />
                                </label>
                            </div>
                            <span style={{ fontSize: '0.8rem', marginTop: '0.5rem' }}>Só serão permitidos upload de arquivos nos formatos png, jpeg, pdf, zip ou rar.</span><br/>
                            {!validate.files.ok && <span className="feedback-error">{validate.files.message}</span>}<br/>
                            <Button className="btn-primary" type="submit" style={{ marginTop: '1rem' }}>
                                Salvar
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default TemplateForm;