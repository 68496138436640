import { FormInteface } from "../../models/form";
import { Validate } from "../../models/validate";

export const RIGHT = 'RIGHT';
export const LEFT = 'LEFT';
export const TOP = 'TOP';

export const defaultForm = {
    name: '',
    json: '',
    type: '',
    groupFromThisRule: false
} as FormInteface

export const defaultValidate = {
    name: {
        message: '',
        ok: true
    },
    json: {
        message: '',
        ok: true
    },
    type: {
        message: '',
        ok: true
    }
} as Validate

export const typeOptions = [
    {
        value: '',
        label: 'Selecione um tipo'
    },
    {
        value: 'date',
        label: 'Data - mm/aaaa'
    },
    {
        value: 'brl',
        label: 'Moeda - R$'
    },
    {
        value: 'text',
        label: 'Texto'
    }
]