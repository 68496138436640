import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Button, Card, Container, Row, Col, Navbar } from 'react-bootstrap';
import BaseProps from '../models/baseProps';
import { appFetch } from '../services/fetch';
import Auth from '../models/auth';

const Link = styled.a`
    color: #007bff;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const emailRgx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;

const Login = ({ history }: BaseProps): JSX.Element => {

    const [form, setForm] = useState({
        email: '',
        password: ''
    })
    const [validate, setValidate] = useState({
        email: {
            message: '',
            ok: true
        },
        password: {
            message: '',
            ok: true
        }
    })
    const [requestError, setRequestError] = useState(false)

    const isValid = () => {
        const emailStatus = form.email.match(emailRgx) ? true : false
        const passwordStatus = form.password !== '' ? true: false

        setValidate({
            email: {
               message: emailStatus ? '' : 'Digite um email válido',
               ok: emailStatus
            },
            password: {
                message: passwordStatus ? '' : 'Digite um senha válida',
                ok: passwordStatus  
            }
        })

        return emailStatus && passwordStatus
    }

    const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        setRequestError(false)
        if (isValid()) {
            const { email, password } = form
            const body = { email, password }          
            try {
                const { token } = await appFetch('user/auth', 'POST', {}, body) as Auth;
                sessionStorage.setItem('me', token)
                history.push('/')

            } catch (error) {
                setRequestError(true)
            }
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequestError(false)
        setValidate({
            ...validate,
            [event.target.name]: {
                message: '',
                ok: true
            }
        })
        setForm({...form, [event.target.name]: event.target.value})
    }

    return (
        <React.Fragment>
            <Navbar style={{ margin: 0, borderBottom: '1px solid #e7e7e7', height: '57px' }} bg="dark">
                <Navbar.Brand>
                    <span>
                        {/* <img src={logo} alt="Start React" title="Start React" /> */}
                        {/* <span style={{ color: '#fff' }}>&nbsp;Level Group Admin</span> */}
                    </span>
                </Navbar.Brand>
            </Navbar>
            <Container>
                <div style={{ marginTop: '4rem' }}>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8}>
                            <Card>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="Email"
                                                value={form.email}
                                                onChange={handleChange}
                                                isInvalid={!validate.email.ok}
                                            />
                                            <Form.Control.Feedback type="invalid">{validate.email.message}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Senha</Form.Label>
                                            <Form.Control
                                                name="password"
                                                type="password"
                                                placeholder="Senha"
                                                value={form.password}
                                                onChange={handleChange}
                                                isInvalid={!validate.password.ok}
                                            />
                                            <Form.Control.Feedback type="invalid">{validate.password.message}</Form.Control.Feedback>
                                        </Form.Group>
                                        {requestError && <div><span className="feedback-error">Email ou senha inválidos</span><br/><br/></div>}
                                        <Button className="btn-primary" type="submit">
                                            Entrar
                                        </Button>
                                    </Form>
                                    <div style={{ textAlign: 'center' }}><Link href="/reset-password">Esqueceu a senha?</Link></div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default Login