import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import Login from './pages/Login';
import Internal from './pages/Internal';
import isAuthenticated from './auth';
import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import ResetPassword from './pages/ResetPassword';

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  component: React.ElementType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }: PrivateRouteProps): JSX.Element => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }
  />
);

const App = (): JSX.Element => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/reset-password" component={ResetPassword} exact />
      <PrivateRoute path="/" component={Internal} />
    </Switch>
  </BrowserRouter>
);

export default App;
