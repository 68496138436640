import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Image } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { TemplateType } from '../../models/templateType';
import { Files } from '../../models/files';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';
import { appFetch, storageFetch } from '../../services/fetch';
import { Template } from '../../models/template';
import Url from '../../models/url';
import ReactLoading from 'react-loading';
import alert from '../../components/common/alert';

type TParams = { id: string };

const TemplateDetail = ({ match, history }: RouteComponentProps<TParams>): JSX.Element => {
    const [template, setTemplate] = useState({
        name: '',
        type: {
            name: '',
            description: ''
        } as TemplateType,
        files: {
            folder: '',
            defaultFile: ''
        } as Files
    });
    const [files, setFiles] = useState([] as string[]);
    const [srcs, setSrcs] = useState([] as string[]);
    const [isLoading, setIsLoading] = useState(false);

    const { id } = match.params;

    const breadcrumb = {
        path: `/templates/${id}/detail`,
        value: 'Detalhar Template'
    }

    const breadcrumbs = [
        {
            path: '/',
            value: 'Home'
        },
        {
            path: '/templates',
            value: 'Templates'
        },
        breadcrumb
    ]

    useEffect(() => {
        if (id) getTemplate(id)
    }, [])

    const getTemplate = async (id: string) => {
        setIsLoading(true)

        try {
            const template = await appFetch(`template/${id}`, 'GET') as Template;
            const { paths } = await appFetch(`utils/files/${id}`, 'GET') as Url;
    
            const files = paths || []
            const urls = await Promise.all(files.map(async path =>await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url))
            const blobResponses = await Promise.all(urls.map(async ({ signedURL }) => await storageFetch(signedURL || '', 'GET') as Response))
            const blobs = await Promise.all(blobResponses.map(async response => await response?.blob()))
            const srcs = await Promise.all(blobs.map(blob => URL.createObjectURL(blob)))
            
            setSrcs(srcs)
            setFiles(files)
            setTemplate(template)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível recuperar as informações do template',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
            history.push('/templates')
        }
    }

    const onViewFile = async (path: string) => {
        const { signedURL } = await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url;
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = signedURL || '';
        link.click();
    }

    return (
        <React.Fragment>
            {isLoading &&
            <div style={{ width: 'calc(100% - 251px)', height: 'calc(100vh - 57px)', position: 'absolute', zIndex: 1, backgroundColor: '#000', opacity: 0.2 }}>
                <ReactLoading className='spinner-margin' type={'spin'} color={'#007bff'} height={'250px'} width={'250px'} />
            </div>}
            <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
                <Row>
                    <Col><h1>Detalhar Template</h1></Col>
                    <Col>
                        <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                    </Col>
                </Row>
                <hr/>
                <br/>
                <Row>
                    <Col>
                        <Form noValidate>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            value={template.name}
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formBasicType">
                                        <Form.Label>Tipo</Form.Label>
                                        <Form.Control
                                            name="type"
                                            type="text"
                                            value={template.type.name}
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {!!files.length &&
                            <div style={{ marginTop: '10px' }}>
                                <span>Arquivos</span>
                                <div style={{ height: '480px', overflowY: 'scroll', overflowX: 'hidden', marginBottom: '20px', marginTop: '10px' }}>
                                    <Row xs={1} md={6} style={{ margin: '0' }}>
                                        {!!files.length && files.map((path, index)  => (
                                            <Col key={path} style={{ textAlign: 'center', marginTop: '20px' }} onClick={() => onViewFile(path)}>
                                                <div style={{ position: 'relative' }}>
                                                    <Image className='img-card-boder' src={srcs[index]}></Image>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>}
                        </Form>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default TemplateDetail;