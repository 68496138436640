import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Card, Accordion, Row, Col, Badge, Table } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import CustomBreadcrumb from '../components/common/Breadcrumb/CustomBreadcrumb';
import { appFetch } from '../services/fetch';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PredictingLog from '../models/predictingLog';
import Url from '../models/url';
import alert from '../components/common/alert';
import ExtractingLog from '../models/extractingLog';
import ProcessingLog from '../models/processingLog';

const Link = styled.a`
    color: #007bff;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

type TParams = { id: string, flow: string, requestId: string };

interface LogsProps extends RouteComponentProps<TParams> {
    setRedirectTo(path: string): void,
    redirectTo: string
}
interface ExtractStatus {
    [key: string]: string
}

const extractStatus = {
    'NOT_EXTRACTED': 'Não extraído',
    'COMPLETED': 'Completo',
    'UNCOMPLETED': 'Incompleto' 
} as ExtractStatus


const Logs = ({ location, match, history, redirectTo, setRedirectTo }: LogsProps): JSX.Element => {
    const [predictingLogs, setPredictingLogs] = useState([] as PredictingLog[])
    const [extractingLogs, setExtractingLogs] = useState([] as ExtractingLog[])
    const [processingLogs, setProcessingLogs] = useState([] as ProcessingLog[])

    const breadcrumbs = [
        {
            path: '/',
            value: 'Home'
        },
        {
            path: `/logs/${match.params.id}/flow/${match.params.flow}/requestId/${match.params.requestId}${location.search}`,
            value: 'Logs'
        }
    ]

    useEffect(() => {
        getLogs()
    }, [])

    useEffect(() => {
        setRedirectTo('')
        if(redirectTo) history.push(redirectTo)
    }, [redirectTo]);

    const getLogs = async () => {
        try {
            const { id, flow } = match.params;

            if (flow === 'processing') {
                const logs = await appFetch(`tracking/${id}/logs/${flow}`, 'GET') as ProcessingLog[]
                setProcessingLogs(logs)
            }

            if (flow === 'predicting') {
                const logs = await appFetch(`tracking/${id}/logs/${flow}`, 'GET') as PredictingLog[]
                setPredictingLogs(logs)
            }

            if (flow === 'extracting') {
                const logs = await appFetch(`tracking/${id}/logs/${flow}`, 'GET') as ExtractingLog[]
                setExtractingLogs(logs)
            }
        } catch (error) {
            await alert({
                text: 'Não foi possível recuperar os logs',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
            history.push('/')
        }
    }

    const onViewFile = async (path: string) => {
        try {
            const { signedURL }  = await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url;
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = signedURL || '';
            link.click();
        } catch (error) {
            await alert({
                text: 'Não foi possível recuperar o documento',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }

    }

    const getLogType = () => {
        if (match.params.flow === 'processing') return 'Processamento'
        if (match.params.flow === 'predicting') return 'Classificação'
        if (match.params.flow === 'extracting') return 'Extração'
    }

    const getLogDate = () => {
        const { search } = location;
        const match = search.match(/(?<=\?createdAt=).*$/g) || []
        const createdAt = match[0]
        return `${new Date(createdAt).toLocaleDateString('pt-br')} às ${new Date(createdAt).toLocaleTimeString('pt-br')}`
    }

    return (
        <div className='content-wrapper'>
            <Row>
                <Col>
                    <h1>{`Logs de ${getLogType()}`}</h1>
                    <h5>{match.params.requestId}</h5>
                    <h5>{getLogDate()}</h5>
                </Col>
                <Col>
                    <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                </Col>
            </Row>
            <hr/>
            <br/>
            <Row>
                <Col>
                    <Accordion>
                        {match.params.flow === 'processing' && !!processingLogs.length && processingLogs.map((log, index) => (
                            <Card key={index}>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                                        <span>{`${log.message.name}.${log.message.extensaoDocumento} `} <FontAwesomeIcon icon={faChevronDown} /></span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={index.toString()}>
                                    <Card.Body>
                                        <span><strong>Competência: </strong>{log.message.competencia}</span><br/>
                                        <span><strong>Nome do arquivo comprimido: </strong>{log.message.compressedFileName}</span><br/>
                                        <span><strong>Extensão do arquivo comprimido: </strong>{log.message.extensaoDocumentoComprimido}</span><br/>
                                        <span><strong>Nome do documento: </strong>{log.message.name}</span><br/>
                                        <span><strong>Extensão do documento: </strong>{log.message.extensaoDocumento}</span><br/>
                                        <span><strong>Modelo: </strong>{log.message.modelId}</span><br/>
                                        <span><strong>Fornecedor: </strong>{log.message.fornecedorCNPJ}</span><br/>
                                        <span><strong>Documentos: </strong></span><br/>
                                        <ul style={{ listStyle: 'none', padding: '0' }}>
                                            {log.message.paths.map((path, index) => (
                                                <li key={index}>
                                                    <span><Link onClick={() => onViewFile(path)}>{path.match(/(?<=\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-).*$/g)?.pop()}</Link></span>
                                                </li>
                                            ))}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                        {match.params.flow === 'predicting' && !!predictingLogs.length && predictingLogs.map((log, index) => (
                            <Card key={index}>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                                        <span>{`${log.message.path.match(/(?<=\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-).*$/g)?.pop()} `} <FontAwesomeIcon icon={faChevronDown} /></span>
                                    </Accordion.Toggle>
                                    {log.message.valid
                                    ? <Badge pill variant="success">Indentificado</Badge>
                                    : <Badge pill variant="danger">Não indentificado</Badge>}
                                </Card.Header>
                                <Accordion.Collapse eventKey={index.toString()}>
                                    <Card.Body>
                                        <span><strong>Template: </strong>{log.message.template.name}</span><br/>
                                        <span><strong>Indentificado: </strong>{log.message.valid ? 'Sim': 'Não'}</span><br/>
                                        <span><strong>Modelo: </strong>{log.message.modelId}</span><br/>
                                        <span><strong>Fornecedor: </strong>{log.message.fornecedorCNPJ}</span><br/>
                                        <span><strong>Acurácia: </strong>{log.message.accuracy}</span><br/><br/>
                                        <span><Link onClick={() => onViewFile(log.message.path)}>Ver documento</Link></span><br/>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                        {match.params.flow === 'extracting' && !!extractingLogs.length && extractingLogs.map((log, index) => (
                            <Card key={index}>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                                        <span>{`${log.message.path.match(/(?<=\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-).*$/g)?.pop()} `} <FontAwesomeIcon icon={faChevronDown} /></span>
                                    </Accordion.Toggle>
                                    {log.message.status === 'COMPLETED' && <Badge pill variant="success">Completo</Badge>}
                                    {log.message.status === 'UNCOMPLETED' && <Badge pill variant="warning">Incompleto</Badge>}
                                    {log.message.status === 'NOT_EXTRACTED' && <Badge pill variant="danger">Não extraído</Badge>}
                                </Card.Header>
                                <Accordion.Collapse eventKey={index.toString()}>
                                    <Card.Body>
                                        <span><strong>Template: </strong>{log.message.template.name}</span><br/>
                                        <span><strong>Indentificado: </strong>{log.message.valid ? 'Sim': 'Não'}</span><br/>
                                        <span><strong>Modelo: </strong>{log.message.modelId}</span><br/>
                                        <span><strong>Fornecedor: </strong>{log.message.fornecedorCNPJ}</span><br/>
                                        <span><strong>Acurácia: </strong>{log.message.accuracy}</span><br/>
                                        <span><strong>Status: </strong>{extractStatus[log.message.status]}</span><br/>
                                        <span><strong>Campos extraídos:</strong></span><br/><br/>
                                        {log.message.rules.map((rule, index) => (
                                            <Table key={index}>
                                                <thead>
                                                    <tr>
                                                        <th>Campo</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {Object.keys(rule).map((key, index) => (
                                                    <tr key={index}>
                                                        <td>{key}</td>
                                                        <td>{rule[key]}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        ))}
                                            
                                        <span><Link onClick={() => onViewFile(log.message.path)}>Ver documento</Link></span><br/>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </div>
    )
}

export default Logs