import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Navbar } from 'react-bootstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from '../Sidebar/Sidebar';
import './Header.css'

interface HeaderProps {
    onRedirect(value: string): void
}

const IconWrapper = styled.div`
    position: absolute;
    cursor: pointer;
    right: 1rem;
    @media(min-width:768px) {
        display:none
    }
`;

const LogoutWrapper = styled.div`
    position: absolute;
    cursor: pointer;
    right: 1rem;
    @media(max-width:768px) {
        right: 2.5rem;
    }
`;

const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return width
}

const Header = ({ onRedirect }: HeaderProps): JSX.Element => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [isSidebarVisible, setIsSidebarVisible] = useState(windowDimensions > 768)

    const handleSidebarVisibility = () => {
        setIsSidebarVisible(!isSidebarVisible)
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsSidebarVisible(windowDimensions > 768)
    }, [windowDimensions]);

    return (
        <React.Fragment>
            <div id="wrapper" className="content">
                <Navbar style={{ margin: 0, borderBottom: '1px solid #e7e7e7', height: '57px' }} bg="dark">
                    <Navbar.Brand>
                        <span>
                            {/* <img src={logo} alt="Start React" title="Start React" /> */}
                            {/* <span style={{ color: '#fff' }}>&nbsp;Level Group Admin</span> */}
                        </span>
                    </Navbar.Brand>
                    <LogoutWrapper>
                        <Button onClick={() => {
                            sessionStorage.setItem('me', '')
                            onRedirect('/')
                        }}>Logout</Button>
                    </LogoutWrapper>
                    <Sidebar
                        setIsSidebarVisible={setIsSidebarVisible}
                        isVisible={isSidebarVisible}
                        windowDimensions={windowDimensions}
                        onRedirect={onRedirect}
                    />
                    <IconWrapper onClick={handleSidebarVisibility}><FontAwesomeIcon style={{ color: '#fff' }} icon={faEllipsisV} /></IconWrapper>
                </Navbar>
            </div>
        </React.Fragment>
    )
}

export default Header