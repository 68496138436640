import React, { useEffect, useState, useRef, MutableRefObject } from 'react'
import { Button, Row, Col, Table, Pagination } from 'react-bootstrap';
import BaseProps from '../../models/baseProps';
import User from '../../models/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faEdit, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { appFetch } from '../../services/fetch';
import alert from '../../components/common/alert';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';
import { UserPagination } from '../../models/userPagination';

const breadcrumbs = [
    {
        path: '/',
        value: 'Home'
    },
    {
        path: '/users',
        value: 'Usuários'
    }
]

const UserList = ({ history }: BaseProps): JSX.Element => {
    const [users, setUsers] = useState<Array<User>>([]);
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 8,
        pages: 0
    });
    const [isLoading, setIsLoading] = useState(false)
    const searchInput = useRef() as MutableRefObject<HTMLInputElement>

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = async () => {
        setIsLoading(true)
        try {
            const { page, limit } = listParams;
            const {docs, pages} = await appFetch(`user?page=${page}&limit=${limit}`, 'GET') as UserPagination;
            setUsers(docs)
            setListParams({...listParams, pages});
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível carregar a lista de usuários',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const search = async () => {
        setIsLoading(true)
        try {
            const { limit } = listParams;
            const { docs, pages } = await appFetch(`user?page=1&limit=${limit}&filter=${searchInput.current.value}`, 'GET') as UserPagination;
            setUsers(docs);
            setListParams({...listParams, pages, page: 1});
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível carregar a lista de usuários',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const onChangePage = async (page: number) => {
        if(page > 0 && page <= listParams.pages) {
            const { limit } = listParams;
            const query = searchInput.current.value ? `page=${page}&limit=${limit}&filter=${searchInput.current.value}` : `page=${page}&limit=${limit}`
            setIsLoading(true)
            try {
                const { docs, pages } = await appFetch(`user?${query}`, 'GET') as UserPagination;
                setUsers(docs);
                setListParams({...listParams, pages, page});
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                await alert({
                    text: 'Não foi possível carregar a lista de usuários',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const resetTempaltes = async () => {
        setIsLoading(true)
        try {
            const { limit } = listParams;
            const { docs, pages } = await appFetch(`user?page=1&limit=${limit}`, 'GET') as UserPagination;
            setUsers(docs)
            setListParams({...listParams, pages, page: 1 })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível carregar a lista de usuários',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const cleanupSearch = async () => {
        searchInput.current.value = ''
        await resetTempaltes()
    }

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!event.target.value) await resetTempaltes()
    }

    const onDeleteTemplate = async (id: string) => {
        const result = await alert({
            title: 'Deletar usuário',
            text: 'O usuário será deletado permanetemente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar'
        })

        if(result.isConfirmed) deteteUser(id)
    }

    const deteteUser = async (id: string) => {
        try {
            await appFetch(`user/${id}`, 'DELETE')

            await alert({
                text: 'Usuário deletado com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok',
            })

            const newUserList = users.filter(user => user._id !== id)
            setUsers(newUserList)
        } catch (error) {
            await alert({
                text: 'Não foi possível deleter o usuário',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const renderRow = (user: User) => {
        const detailRoute = `/users/${user._id}/detail`;
        const editRoute = `/users/${user._id}/edit`;
        return (
            <tr key={user.cpf}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.cpf}</td>
                <td>{user.active ? 'Ativo' : 'Inativo'}</td>
                <td>
                    <a href={detailRoute}>
                        <FontAwesomeIcon icon={faEye} />
                    </a>
                    &nbsp;&nbsp;
                    <a href={editRoute}>
                        <FontAwesomeIcon icon={faEdit} />
                    </a>
                    &nbsp;&nbsp;
                    <a href="#" onClick={() => onDeleteTemplate(user._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </a>
                </td>
            </tr>
        )
    }

    return (
        <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
            <Row>
                <Col><h1>Usuários</h1></Col>
                <Col>
                    <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                </Col>
            </Row>
            <hr/>
            <br/>
            <Row>
                <Col>
                    <div className="input-group custom-search-form">
                        <input onChange={handleChange} ref={searchInput} type="text" className="form-control" placeholder="Pesquisar..." />
                        <span className="input-group-btn">
                            <button className="btn btn-default" type="button" onClick={search}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </span>
                        {searchInput?.current?.value && <Button disabled={isLoading} onClick={cleanupSearch} style={{ marginLeft: '1rem' }}>Limpar pesquisa</Button>}
                    </div>
                </Col>
            </Row>
            <br/>
            <Row style={{ textAlign: 'start' }}>
                <Col>
                    <Button onClick={() => history.push('/users/add')}>
                        <FontAwesomeIcon icon={faPlus} />&nbsp;Cadastrar usuário
                    </Button>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Cpf</th>
                                <th>Ativo</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(renderRow)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <br/>
            {!!users.length && <Row className="justify-content-center">
                <Col xs={'auto'} style={isLoading ? { position: 'absolute', bottom: '1rem', pointerEvents: 'none', opacity: '0.5' } : { position: 'absolute', bottom: '1rem' }}>
                    <Pagination>
                        <Pagination.Prev onClick={() => onChangePage(listParams.page - 1)} />
                        {Array.from(Array(listParams.pages).keys()).map(page => (
                            <Pagination.Item onClick={() => onChangePage(page + 1)} active={page + 1 === listParams.page} key={page}>{page + 1}</Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => onChangePage(listParams.page + 1)} />
                    </Pagination>
                </Col>
            </Row>}
            {!users.length && <div style={{ textAlign: 'center' }}><span>Não foram encontrados usuários</span></div>}
        </div>
    )
}

export default UserList;



