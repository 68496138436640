import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import CustomBreadcrumb from '../components/common/Breadcrumb/CustomBreadcrumb';
import BaseProps from '../models/baseProps';
import { faUpload, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    VerticalBarSeries,
    VerticalBarSeriesPoint,
    makeVisFlexible,
    XYPlot,
    HorizontalGridLines,
    XAxis,
    YAxis,
    RadialChart,
    Hint,
    RadialChartPoint,
    Crosshair,
    DiscreteColorLegend
} from 'react-vis';
import 'react-vis/dist/style.css';
import { appFetch } from '../services/fetch';
import Model from '../models/model';
import Tracking from '../models/tracking';
import alert from '../components/common/alert';

const FlexibleXYPlot = makeVisFlexible(XYPlot);
const FlexibleRadialChart = makeVisFlexible(RadialChart);

const Link = styled.a`
    color: #007bff;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

interface DashboardProps extends BaseProps{
    setRedirectTo(path: string): void
}

const breadcrumbs = [
    {
        path: '/',
        value: 'Home'
    }
]

const tipStyle = {
    display: 'flex',
    color: '#fff',
    background: '#000',
    alignItems: 'center',
    padding: '5px'
};

interface CrosshairValues {
    x: string | number,
    y: number
}

const Dashboard = ({ history, redirectTo, setRedirectTo }: DashboardProps): JSX.Element => {
    const [model, setModel] = useState({} as Model)
    const [models, setModels] = useState([] as Model[])
    const [tracking, setTracking] = useState([] as Tracking[]) 
    const [radialChartData, setRadialChartData] = useState([] as RadialChartPoint[])
    const [lossVerticalBarChartData, setLossVerticalBarChartData] = useState([] as VerticalBarSeriesPoint[])
    const [accuracyVerticalBarChartData, setAccuracyVerticalBarChartData] = useState([] as VerticalBarSeriesPoint[])
    const [lossCrosshairValues, setLossCrosshairValues] = useState([] as CrosshairValues[])
    const [accuracyCrosshairValues, setAccuracyCrosshairValues] = useState([] as CrosshairValues[])
    const [hoveredCell, setHoveredCell] = useState({} as RadialChartPoint)

    useEffect(() => {
        setRedirectTo('')
        if(redirectTo) history.push(redirectTo)
    }, [redirectTo]);

    useEffect(() => {
        getModels()
    }, []);

    const getModels = async () => {
        const models = await appFetch(`models`, 'GET') as Model[];
        const model = models.find(model => model.published) || models[0]
        const tracking = await appFetch(`tracking/${model._id}`, 'GET') as Tracking[]

        const radialChartData = tracking.length && model.status === 'COMPLETED'
        ? [
            {angle: tracking.reduce((acc, curr) => acc + (curr.processing?.quantity || 0), 0), label: 'Processados'},
            {angle: tracking.reduce((acc, curr) => acc + (curr.predicting?.quantity || 0), 0), label: 'Classificados', style: {fill: '#FF5733', stroke: '#FF5733'}},
            {angle: tracking.reduce((acc, curr) => acc + (curr.extracting?.quantity || 0), 0), label: 'Extraidos'}
        ]
        : []

        const modelLoss = models.filter(m => m._id === model._id).map(m => ({x: m._id, y: m.loss}))
        const modelAccuracy = models.filter(m => m._id === model._id).map(m => ({x: m._id, y: m.validation_accuracy*100}))
        const lastModels = models.filter(m => m.status === 'COMPLETED').slice(-14, models.length).filter(m => m._id !== model._id)

        const lossVerticalBarChartData = model.status === 'COMPLETED' ? [...modelLoss, ...lastModels.map(m => ({x: m._id, y: m.loss}))] : []
        const accuracyVerticalBarChartData = model.status === 'COMPLETED' ?[...modelAccuracy, ...lastModels.map(m => ({x: m._id, y: m.validation_accuracy*100}))] : []
        
        setModels(models)
        setModel(model)
        setTracking(tracking)
        setRadialChartData(radialChartData)
        setLossVerticalBarChartData(lossVerticalBarChartData)
        setAccuracyVerticalBarChartData(accuracyVerticalBarChartData)
    }

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = models.find(model => model._id === event.target.value) || {} as Model
        const tracking = await appFetch(`tracking/${model._id}`, 'GET') as Tracking[]

        const radialChartData = tracking.length && model.status === 'COMPLETED'
        ? [
            {angle: tracking.reduce((acc, curr) => acc + (curr.processing?.quantity || 0), 0), label: 'Processados'},
            {angle: tracking.reduce((acc, curr) => acc + (curr.predicting?.quantity || 0), 0), label: 'Classificados', style: {fill: '#FF5733', stroke: '#FF5733'}},
            {angle: tracking.reduce((acc, curr) => acc + (curr.extracting?.quantity || 0), 0), label: 'Extraidos'}
        ]
        : []

        const modelLoss = models.filter(m => m._id === model._id).map(m => ({x: m._id, y: m.loss}))
        const modelAccuracy = models.filter(m => m._id === model._id).map(m => ({x: m._id, y: m.validation_accuracy*100}))
        const lastModels = models.filter(m => m.status === 'COMPLETED').slice(-14, models.length).filter(m => m._id !== model._id)

        const lossVerticalBarChartData = model.status === 'COMPLETED' ? [...modelLoss, ...lastModels.map(m => ({x: m._id, y: m.loss}))] : []
        const accuracyVerticalBarChartData = model.status === 'COMPLETED' ?[...modelAccuracy, ...lastModels.map(m => ({x: m._id, y: m.validation_accuracy*100}))] : []

        setModel(model)
        setTracking(tracking)
        setRadialChartData(radialChartData)
        setLossVerticalBarChartData(lossVerticalBarChartData)
        setAccuracyVerticalBarChartData(accuracyVerticalBarChartData)
    }

    const onNearestX = (verticalBarSeriesPoint: VerticalBarSeriesPoint, index: number, type: string) => {

        const modelLoss = models.filter(m => m._id === model._id).map(m => ({x: m._id, y: m.loss}))
        const modelAccuracy = models.filter(m => m._id === model._id).map(m => ({x: m._id, y: m.validation_accuracy*100}))
        const lastModels = models.filter(m => m.status === 'COMPLETED').slice(-14, models.length).filter(m => m._id !== model._id)

        if (type === 'loss') {
            const verticalBarSeriesData = [
                [...modelLoss, ...lastModels.map(m => ({x: m._id, y: m.loss}))]
            ]
            setLossCrosshairValues(verticalBarSeriesData.map((d) => d[index]))
        }

        if (type === 'accuracy') {
            const verticalBarSeriesData = [
                [...modelAccuracy, ...lastModels.map(m => ({x: m._id, y: m.validation_accuracy*100}))]
            ]
            setAccuracyCrosshairValues(verticalBarSeriesData.map((d) => d[index]))
        }
    }

    const buildValue = (hoveredCell: RadialChartPoint) => {
        const { radius, angle, angle0 } = hoveredCell;
        const truedAngle = (angle + angle0) / 2;
        return {
          x: radius ? radius * Math.cos(truedAngle) : 0,
          y: radius ? radius * Math.sin(truedAngle) : 0
        };
    }

    const getStatusQuantity = (label: string) => {
        if (label === 'Processados') return tracking.reduce((acc, curr) => acc + (curr.processing?.quantity || 0), 0)
        if (label === 'Classificados') return tracking.reduce((acc, curr) => acc + (curr.predicting?.quantity || 0), 0)
        if (label === 'Extraidos') return tracking.reduce((acc, curr) => acc + (curr.extracting?.quantity || 0), 0)
    }

    const getModelLabel = (m: Model) => {
        if (m.status === 'PROCESSING') return `Versão ${m._id} - Processando`
        if (m.status === 'FAILED') return `Versão ${m._id} - Falhou`
        if (m.status === 'COMPLETED' && m.published) return `Versão ${m._id} - Publicado`
        return `Versão ${m._id}`
    }

    const handlePublish = async () => {
        try {
            await appFetch(`integration/publish/${model._id}`, 'GET');

            await alert({
                text: 'Versão publicada com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok',
            })

            window.location.reload();
        } catch (error) {
            await alert({
                text: 'Não foi possível publicar a versão',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    return (
        <div className='content-wrapper'>
            <Row>
                <Col><h1>Dashboard</h1></Col>
                <Col>
                    <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                </Col>
            </Row>
            <hr/>
            <br/>
            <Row>
                <Col>
                    <Form>
                        <Form.Group controlId="formBasicVersion">
                            <Row>
                                <Col xs={'auto'} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h5 style={{ marginBottom: '0' }}>Modelos:</h5>
                                </Col>
                                <Col>
                                    <Form.Control
                                        as="select"
                                        name="model"
                                        value={model._id}
                                        onChange={handleChange}
                                    >
                                        {models.map((model, index) => (<option key={index} value={model._id}>{getModelLabel(model)}</option>))}    
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    <Button disabled={model.status !== 'COMPLETED'} onClick={handlePublish}><FontAwesomeIcon icon={faUpload} /> &nbsp;Publicar</Button>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card style={{ boxShadow: '0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)' }}>
                        <Card.Body>
                            <h5 style={{ marginBottom: '0' }}><FontAwesomeIcon style={{ color: '#007bff' }} icon={faCalendarAlt} />{` Data do treinamento: ${model.status === 'COMPLETED' ? `${new Date(model.trained_at).toLocaleDateString('pt-br')} às ${new Date(model.trained_at).toLocaleTimeString('pt-br')}` : ''}`}</h5>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card style={{ boxShadow: '0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)' }}>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h3>Comparativo entre modelos</h3><hr/>
                                </Col>
                            </Row>
                            {model.status === 'COMPLETED'
                            ? <Row>
                                <Col xs={6}>
                                    <br/>
                                    <br/>
                                    <FlexibleXYPlot height={300}  xType="ordinal" onMouseLeave={() => setAccuracyCrosshairValues([])}>
                                        <HorizontalGridLines />
                                        <XAxis tickLabelAngle={-45} />
                                        <YAxis />
                                        <VerticalBarSeries
                                            color={'rgb(255, 87, 51)'}
                                            style={{stroke: '#fff'}}
                                            barWidth={1}
                                            data={accuracyVerticalBarChartData}
                                            onNearestX={(verticalAreaSeriesPoint, { index }) => onNearestX(verticalAreaSeriesPoint, index, 'accuracy')}
                                        />
                                        <Crosshair values={accuracyCrosshairValues} />
                                        <DiscreteColorLegend
                                            className='vertical-bar-legends'
                                            orientation="horizontal"
                                            items={[{ title:'Acurácia', color: 'rgb(255, 87, 51)' }]}
                                        />
                                    </FlexibleXYPlot>
                                </Col>
                                <Col xs={6}>
                                    <br/>
                                    <br/>
                                    <FlexibleXYPlot height={300}  xType="ordinal" onMouseLeave={() => setLossCrosshairValues([])}>
                                        <HorizontalGridLines />
                                        <XAxis tickLabelAngle={-45} />
                                        <YAxis />
                                        <VerticalBarSeries
                                            style={{stroke: '#fff'}}
                                            barWidth={1}
                                            data={lossVerticalBarChartData}
                                            onNearestX={(verticalAreaSeriesPoint, { index }) => onNearestX(verticalAreaSeriesPoint, index, 'loss')}
                                        />
                                        <Crosshair values={lossCrosshairValues} />
                                        <DiscreteColorLegend
                                            className='vertical-bar-legends'
                                            orientation="horizontal"
                                            items={[{ title:'Perda', color: 'rgb(18, 147, 154)' }]}
                                        />
                                    </FlexibleXYPlot>
                                </Col>
                            </Row>
                            : <h5>Não há dados para esse modelo</h5>}
                            {model.status === 'COMPLETED' && 
                            <Row>
                                <Col>
                                    <span style={{ fontSize: '0.8rem'}}>* Comparação feita com os últimos 14 modelos gerados com sucesso</span>
                                </Col>
                            </Row>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col xs={6}>
                    <Card style={{ boxShadow: '0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)' }}>
                        <Card.Body>
                            <h3>Status</h3><hr/>
                            {radialChartData.length
                            ? <FlexibleRadialChart
                                data={radialChartData}
                                height={300}
                                showLabels={true}
                                onValueMouseOver={(v: RadialChartPoint) => setHoveredCell(v.x && v.y ? v : {} as RadialChartPoint)}
                                onValueMouseOut={() => setHoveredCell({} as RadialChartPoint)}
                            >
                                {hoveredCell.x && hoveredCell.y ? (
                                <Hint value={buildValue(hoveredCell)}>
                                    <div style={tipStyle}>
                                        {`${hoveredCell.label}: ${getStatusQuantity(hoveredCell.label || '')}`}
                                    </div>
                                </Hint>
                                ) : null}
                            </FlexibleRadialChart>
                            : <h5>Não há dados para esse modelo</h5>}
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card style={{ boxShadow: '0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)' }}>
                        <Card.Body>
                            <h3>Informações do Modelo</h3><hr/>
                            {model.status === 'COMPLETED' ?
                            <div>
                                <h5><strong>Status:</strong>{` ${model.status}`}</h5>
                                <h5><strong>Perda:</strong>{` ${model.loss*100}%`}</h5>
                                <h5><strong>Acurácia:</strong>{` ${model.validation_accuracy*100}%`}</h5>
                                <h5><strong>Tempo de treinamento:</strong>{` ${model.training_elapsed_time_seconds}s`}</h5>
                                <h5><strong>Publicado:</strong>{` ${model.published ? 'Sim' : 'Não'}`}</h5>
                            </div>
                            : <h5>Não há dados para esse modelo</h5>}
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card style={{ boxShadow: '0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)' }}>
                        <Card.Body>
                            <h3>Processamento</h3><hr/>
                            <span><strong>Total: </strong>{tracking.reduce((acc, curr) => acc + (curr.processing?.quantity || 0), 0)}</span>
                            <br/>
                            <br/>
                            <h4>Logs</h4><hr/>
                            {!!tracking.filter(t => t.processing).length
                            ? <ul style={{ listStyle: 'none', padding: '0' }}>
                                {tracking.filter(t => t.processing).map(t => (
                                    <li key={t.requestId}>
                                        <span>{`${new Date(t.createdAt).toLocaleDateString('pt-br')} às ${new Date(t.createdAt).toLocaleTimeString('pt-br')} `}</span>
                                        <Link href={`logs/${t._id}/flow/processing/requestId/${t.requestId}?createdAt=${t.createdAt}`}>{t.requestId}</Link>
                                        <span>  Processados: <strong>{t.processing?.quantity}</strong></span>
                                    </li>
                                ))}
                            </ul>
                            :<span>Não há logs para serem exibidos</span>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card style={{ boxShadow: '0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)' }}>
                        <Card.Body>
                            <h3>Classificação</h3><hr/>
                            <span><strong>Total: </strong>{tracking.reduce((acc, curr) => acc + (curr.predicting?.quantity || 0), 0)}</span>
                            <br/>
                            <span><strong>Identificados: </strong>{tracking.reduce((acc, curr) => acc + (curr.predicting?.identifiedItems || 0), 0)}</span>
                            <br/>
                            <span><strong>Não identificados: </strong>{tracking.reduce((acc, curr) => acc + (curr.predicting?.notIdentifiedItems || 0), 0)}</span>
                            <br/>
                            <br/>
                            <h4>Logs</h4><hr/>
                            {!!tracking.filter(t => t.predicting).length
                            ? <ul style={{ listStyle: 'none', padding: '0' }}>
                                {tracking.filter(t => t.predicting).map(t => (
                                    <li key={t.requestId}>
                                        <span>{`${new Date(t.createdAt).toLocaleDateString('pt-br')} às ${new Date(t.createdAt).toLocaleTimeString('pt-br')} `}</span>
                                        <Link href={`logs/${t._id}/flow/predicting/requestId/${t.requestId}?createdAt=${t.createdAt}`}>{t.requestId}</Link>
                                        <span> Identificados: <strong>{t.predicting?.identifiedItems}.</strong> Não Identificados: <strong>{t.predicting?.notIdentifiedItems}</strong></span>
                                    </li>
                                ))}
                            </ul>
                            :<span>Não há logs para serem exibidos</span>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card style={{ boxShadow: '0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)' }}>
                        <Card.Body>
                            <h3>Extração</h3><hr/>
                            <span><strong>Total: </strong>{tracking.reduce((acc, curr) => acc + (curr.extracting?.quantity || 0), 0)}</span>
                            <br/>
                            <span><strong>Extraidos completamente: </strong>{tracking.reduce((acc, curr) => acc + (curr.extracting?.completedExtractedItems || 0), 0)}</span>
                            <br/>
                            <span><strong>Extraidos imcompletamente: </strong>{tracking.reduce((acc, curr) => acc + (curr.extracting?.uncompletedExtractedItems || 0), 0)}</span>
                            <br/>
                            <span><strong>Não extraidos: </strong>{tracking.reduce((acc, curr) => acc + (curr.extracting?.notExtractedItems || 0), 0)}</span>
                            <br/>
                            <span><strong>Documentos sem regras: </strong>{tracking.reduce((acc, curr) => acc + (curr.extracting?.documentsWithoutRules || 0), 0)}</span>
                            <br/>
                            <br/>
                            <h4>Logs</h4><hr/>
                            
                            {!!tracking.filter(t => t.extracting).length
                            ? <ul style={{ listStyle: 'none', padding: '0' }}>
                                {tracking.filter(t => t.extracting).map(t => (
                                    <li key={t.requestId}>
                                        <span>{`${new Date(t.createdAt).toLocaleDateString('pt-br')} às ${new Date(t.createdAt).toLocaleTimeString('pt-br')} `}</span>
                                        <Link href={`logs/${t._id}/flow/extracting/requestId/${t.requestId}?createdAt=${t.createdAt}`}>{t.requestId}</Link>
                                        {t.extracting && t.extracting.pagesWithoutRulesAmount ? <span> Páginas sem regras: <strong>{t.extracting?.pagesWithoutRulesAmount}</strong></span>: ''}
                                    </li>
                                ))}
                            </ul>
                            : <span>Não há logs para serem exibidos</span>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard