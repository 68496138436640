import React, { useState, useEffect, useRef, MutableRefObject, } from 'react';
import { Row, Col, Button, Table, Pagination, Badge } from 'react-bootstrap';
import { faPlus, faTrash, faEdit, faFileAlt, faEye, faSearch, faEquals } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Template } from '../../models/template';
import { TemplatePagination } from '../../models/templatePagination';
import BaseProps from '../../models/baseProps';
import alert from '../../components/common/alert';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';
import { appFetch } from '../../services/fetch';

const breadcrumbs = [
    {
        path: '/',
        value: 'Home'
    },
    {
        path: '/templates',
        value: 'Templates'
    }
]

const TemplateList = ({ history }: BaseProps): JSX.Element => {
    const [templates, setTemplates] = useState([] as Template[]);
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 8,
        pages: 0
    });
    const [isLoading, setIsLoading] = useState(false)
    const searchInput = useRef() as MutableRefObject<HTMLInputElement>

    useEffect(() => {
        getTemplates();
    }, []);

    const getTemplates = async () => {
        setIsLoading(true)
        try {
            const { page, limit } = listParams;
            const { docs, pages } = await appFetch(`template?page=${page}&limit=${limit}`, 'GET') as TemplatePagination;
            setTemplates(docs)
            setListParams({...listParams, pages})
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível carregar a lista de templates',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const search = async () => {
        setIsLoading(true)
        try {
            const { limit } = listParams;
            const { docs, pages } = await appFetch(`template?page=1&limit=${limit}&filter=${searchInput.current.value}`, 'GET') as TemplatePagination;
            setTemplates(docs);
            setListParams({...listParams, pages, page: 1});
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível carregar a lista de templates',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const handleSearch = (ev: React.KeyboardEvent) => {
        if (ev.key === 'Enter') search();
    }

    const onChangePage = async (page: number) => {
        if(page > 0 && page <= listParams.pages) {
            const { limit } = listParams;
            const query = searchInput.current.value ? `page=${page}&limit=${limit}&filter=${searchInput.current.value}` : `page=${page}&limit=${limit}`
            setIsLoading(true)
            try {
                const { docs, pages } = await appFetch(`template?${query}`, 'GET') as TemplatePagination;
                setTemplates(docs);
                setListParams({...listParams, pages, page});
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                await alert({
                    text: 'Não foi possível carregar a lista de templates',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        }
    }

    const resetTempaltes = async () => {
        setIsLoading(true)
        try {
            const { limit } = listParams;
            const { docs, pages } = await appFetch(`template?page=1&limit=${limit}`, 'GET') as TemplatePagination;
            setTemplates(docs)
            setListParams({...listParams, pages, page: 1 })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            await alert({
                text: 'Não foi possível carregar a lista de templates',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const cleanupSearch = async () => {
        searchInput.current.value = ''
        await resetTempaltes()
    }

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!event.target.value) await resetTempaltes()
    }

    const onDeleteTemplate = async (id: string) => {
        const result = await alert({
            title: 'Deletar template?',
            text: 'O template será deletado permanetemente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar'
        })

        if(result.isConfirmed) deteteTemplate(id)
    }

    const deteteTemplate = async (id: string) => {
        try {
            await appFetch(`template/${id}`, 'DELETE')

            await alert({
                text: 'Template deletado com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok',
            })

            const newTemplateList = templates.filter(template => template._id !== id)
            setTemplates(newTemplateList)
        } catch (error) {
            await alert({
                text: 'Não foi possível deleter o template',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    return (
        <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
            <Row>
                <Col><h1>Templates</h1></Col>
                <Col>
                    <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                </Col>
            </Row>
            <hr/>
            <br/>
            <Row>
                <Col>
                    <div className="input-group custom-search-form">
                        <input onChange={handleChange} onKeyDown={handleSearch} ref={searchInput} type="text" className="form-control" placeholder="Pesquisar..." />
                        <span className="input-group-btn">
                            <button className="btn btn-default" type="button" onClick={search} onKeyUp={search}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </span>
                        {searchInput?.current?.value && <Button disabled={isLoading} onClick={cleanupSearch} style={{ marginLeft: '1rem' }}>Limpar pesquisa</Button>}
                    </div>
                </Col>
            </Row>
            <br/>
            <Row style={{ textAlign: 'start' }}>
                <Col>
                    <Button onClick={() => history.push('/templates/add')}>
                        <FontAwesomeIcon icon={faPlus} />&nbsp;Cadastrar template
                    </Button>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Tipo</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!templates.length && templates.map(template => (
                                <tr key={template._id}>
                                    <td>{template.name} {template.rules?.length === 0 ? <Badge variant="danger"> Sem Regras</Badge>: ''} {template.similarityRules?.length === 0 ? <Badge variant="warning"> Sem Similaridades</Badge>: ''}</td>
                                    <td>{template.type.name}</td>
                                    <td>
                                        <a href={`templates/${template._id}/extract`}>
                                            <FontAwesomeIcon icon={faFileAlt} />
                                        </a>
                                        &nbsp;&nbsp;
                                        <a href={`templates/${template._id}/detail`}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </a>
                                        &nbsp;&nbsp;
                                        <a href={`templates/${template._id}/edit`}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </a>
                                        &nbsp;&nbsp;
                                        <a href="#" onClick={() => onDeleteTemplate(template._id)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </a>
                                        &nbsp;&nbsp;
                                        <a href={`templates/${template._id}/similarity-rules`}>
                                            <FontAwesomeIcon icon={faEquals} />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <br/>
            {!!templates.length && <Row className="justify-content-center">
                <Col xs={'auto'} style={isLoading ? { position: 'absolute', bottom: '1rem', pointerEvents: 'none', opacity: '0.5' } : { position: 'absolute', bottom: '1rem' }}>
                    <Pagination>
                        <Pagination.Prev onClick={() => onChangePage(listParams.page - 1)} />
                        {Array.from(Array(listParams.pages).keys()).map(page => (
                            <Pagination.Item onClick={() => onChangePage(page + 1)} active={page + 1 === listParams.page} key={page}>{page + 1}</Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => onChangePage(listParams.page + 1)} />
                    </Pagination>
                </Col>
            </Row>}
            {!templates.length && <div style={{ textAlign: 'center' }}><span>Não foram encontrados templates</span></div>}
        </div>
    )
}

export default TemplateList