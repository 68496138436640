import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
 
const alert = async ({ title, text, icon, showCancelButton, confirmButtonText, cancelButtonText, input, inputOptions }: SweetAlertOptions): Promise<SweetAlertResult> => {
    return (
        await Swal.fire({
            title,
            text,
            icon,
            showCancelButton,
            confirmButtonText,
            cancelButtonText,
            input,
            inputOptions
        })
    )
}

export default alert;