import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom';
import ExtractModal from '../../components/ExtractModal/ExtractModal';
import { Template } from '../../models/template';
import { Rule } from '../../models/rule';
import { Word } from '../../models/word';
import { Paragraph } from '../../models/paragraph';
import { getImgWithBlocksSrc } from './utils';
import { Block } from '../../models/block';
import { BoundingBox } from '../../models/boundingBox';
import alert from '../../components/common/alert';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';
import { appFetch, storageFetch } from '../../services/fetch';
import { FullTextAnnotation } from '../../models/fullTextAnnotation';
import Url from '../../models/url';
import ImagesModal from '../../components/ImagesModal/ImagesModal';
import ReactLoading from 'react-loading';

type TParams = { id: string };

const TemplateExtract = ({ match }: RouteComponentProps<TParams>): JSX.Element => {
    const { id } = match.params;

    const breadcrumbs = [
        {
            path: '/',
            value: 'Home'
        },
        {
            path: '/templates',
            value: 'Templates'
        },
        {
            path: `/templates/${id}/extract`,
            value: 'Extrair Regras'
        }
    ]

    const [closeImagesModal, setCloseImagesModal] = useState(true)
    const [paths, setPaths] = useState([] as string[])
    const [noPathsError, setNoPathsError] = useState(true)
    const [isLoading, setIsLoading] = useState(true);
    const [template, setTemplate] = useState({} as Template)
    const [imgTemplateSrc, setImgTemplateSrc] = useState('')
    const [imgTemplateOriginalSrc, setImgTemplateOriginalSrc] = useState('')
    const [words, setWords] = useState([] as Word[])
    const [closeExtractModal, setCloseExtractModal] = useState(true);
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [rules, setRules] = useState([] as Rule[])
    const [blocksToRender, setBlocksToRender] = useState({
        words: true,
        rules: true
    })

    useEffect(() => {
        getTemplate(id);
    }, [])

    useEffect(() => {
        (async () => {
            const wordsBoundingBoxes = words.map((word: Word) => word.boundingBox)
            const imgWithBlocksSrc = await getImgWithBlocksSrc(imgTemplateOriginalSrc, wordsBoundingBoxes, rules, blocksToRender)
            setImgTemplateSrc(imgWithBlocksSrc)
        })()
    }, [blocksToRender, rules])

    const getTemplate = async (id: string) => {
        const template = await appFetch(`template/${id}`, 'GET') as Template;
        const { paths } = await appFetch(`utils/files/${id}`, 'GET') as Url;

        setPaths(paths || []);
        setTemplate(template);

        if ((!template.rules || (template.rules && template.rules.length === 0)) && paths?.length) {
            setCloseImagesModal(false)
            setNoPathsError(false)
            setIsLoading(false)
        } else if (template.rules && template.rules.length > 0 && paths?.length) {
            const defaultFile = template.files.defaultFile;
            const { pages } = await appFetch(`utils/ocr?path=${defaultFile}`, 'GET') as FullTextAnnotation;
            
            const words = pages[0] && pages[0].blocks.flatMap((block: Block) => block.paragraphs.flatMap((paragraph: Paragraph) => paragraph.words.map((word: Word) => word)));
            const wordsBoundingBoxes= words.map((word: Word) => word.boundingBox)
    
            const rules = template.rules || []
            await getImgTemplateSrcsFromStorage(defaultFile, wordsBoundingBoxes, rules);
    
            setWords(words)
            setRules(rules)
            setNoPathsError(false)
            setIsLoading(false)
        }
    }

    const getImgTemplateSrcsFromStorage = async (defaultFile: string, wordsBoundingBoxes: BoundingBox[], rules: Rule[]) => {
        const path = defaultFile;
        const { signedURL } = await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url;
        const url = signedURL || '';
        const imgTemplateBlobResponse = await storageFetch(url, 'GET') as Response;
        const imgTemplateBlob = await imgTemplateBlobResponse?.blob()
        const imgTemplateOriginalSrc = URL.createObjectURL(imgTemplateBlob)
        const imgWithBlocksSrc = await getImgWithBlocksSrc(imgTemplateOriginalSrc, wordsBoundingBoxes, rules, blocksToRender)
        setImgTemplateSrc(imgWithBlocksSrc)
        setImgTemplateOriginalSrc(imgTemplateOriginalSrc)
    }

    const handleDoubleClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const x = event.nativeEvent.offsetX
        const y = event.nativeEvent.offsetY

        setPosition({ x, y })
        setCloseExtractModal(false)
    }

    const onChangeRules = async (rules: Rule[]) => {
        // await update({ path: `template/update-rules/${id}`, body: { rules } }) as Template
        setRules(rules || [])
    }

    const saveRules = async () => {
        try {
            await appFetch(`template/update-rules/${id}`, 'PUT', {},  { rules }) as Template
            await alert({
                text: 'Regras salvas com sucesso',
                icon: 'success',
                confirmButtonText: 'Ok',
            })
        } catch (error) {
            await alert({
                text: 'Não foi possível salvar as regras',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBlocksToRender({...blocksToRender, [event.target.name]: event.target.checked})
    }

    const onCloseImagesModal = async (selectedPath: string) => {
        setCloseImagesModal(true)

        if (selectedPath) {
            setIsLoading(true)
            const files = { ...template.files, defaultFile: selectedPath };
            await appFetch(`template/update-files/${id}`, 'PUT', {}, { files }) as string[];
            const defaultFile = selectedPath
            const { pages } = await appFetch(`utils/ocr?path=${defaultFile}`, 'GET') as FullTextAnnotation;
            const words = pages[0] && pages[0].blocks.flatMap((block: Block) => block.paragraphs.flatMap((paragraph: Paragraph) => paragraph.words.map((word: Word) => word)));
            const wordsBoundingBoxes= words.map((word: Word) => word.boundingBox)
    
            const rules = template.rules || []
            await getImgTemplateSrcsFromStorage(defaultFile, wordsBoundingBoxes, rules);
    
            setWords(words)
            setRules(rules)
            setNoPathsError(false)
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            {isLoading && <div style={{ width: 'calc(100% - 251px)', height: 'calc(100vh - 57px)', position: 'absolute', zIndex: 1, backgroundColor: '#000', opacity: 0.2 }}>
                <ReactLoading className='spinner-margin' type={'spin'} color={'#007bff'} height={'250px'} width={'250px'} />
            </div>}
            <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
                <Row>
                    <Col><h1>Extrair Regras</h1></Col>
                    <Col>
                        <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                    </Col>
                </Row>
                <hr/>
                <br/>
                {noPathsError && !isLoading &&
                <Row className='justify-content-center'>
                    <Col xs={'auto'}>
                        <span>Não foram encontrados arquivos para esse template</span>
                    </Col>
                </Row>}
                {!isLoading &&
                <React.Fragment>
                    <Row>
                        <Col>
                            <Button style={{ width: '150px' }} onClick={saveRules}>Salvar</Button>
                        </Col>
                        <Col>
                            <Form>
                                <Row>
                                    <Col xs={2}>
                                        <Form.Group controlId="rulesCheckbox">
                                            <Form.Check name="rules" type="checkbox" label="Regras" checked={blocksToRender.rules} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Group controlId="wordsCheckbox">
                                            <Form.Check name="words"  type="checkbox" label="Palavras" checked={blocksToRender.words} onChange={handleChange}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{
                                overflow: 'scroll',
                                maxWidth: '100%',
                                maxHeight: '60vh'
                            }}><img onDoubleClick={handleDoubleClick} src={imgTemplateSrc}></img></div>
                        </Col>
                    </Row>
                </React.Fragment>}
                {!closeExtractModal &&
                <div style={{ position: 'absolute', top: '90px', width: 'calc(100% - 251px)', left: '251px', padding: '0 25px' }}>
                    <ExtractModal
                        setCloseExtractModal={setCloseExtractModal}
                        words={words}
                        position={position}
                        rules={rules}
                        onChangeRules={onChangeRules}
                        closeExtractModal={closeExtractModal}
                    />
                </div>}
                {!closeImagesModal &&
                <div style={{ position: 'absolute', top: '90px', width: 'calc(100% - 251px)', left: '251px', padding: '0 25px' }}>
                    <ImagesModal
                        onCloseImagesModal={onCloseImagesModal}
                        paths={paths}
                    />
                </div>}
            </div>
        </React.Fragment>
    )
}

export default TemplateExtract;