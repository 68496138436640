import { Envs } from "./models/envs";

const envs = {
    local: {
      BASE_URL: "http://localhost:3001",
      BASE_URL_CLASSIFIER: "https://smart-ocr-dev.cactoweb.com.br/smart-ocr-classifier"
    },
    dev: {
      BASE_URL: "https://smart-ocr-dev.cactoweb.com.br/smart-ocr-api",
      BASE_URL_CLASSIFIER: "https://smart-ocr-dev.cactoweb.com.br/smart-ocr-classifier"
    },
    prod: {
      BASE_URL: "https://leituradocumento.levelgestao.com.br/smart-ocr-api",
      BASE_URL_CLASSIFIER: "https://leituradocumento.levelgestao.com.br/smart-ocr-classifier"
    }
  } as Envs
  
  const key: keyof Envs = process.env.REACT_APP_STAGE || 'local'
  const config = envs[key];
    
  export default {
    ...config
  };