import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from "react-bootstrap";
import { RouteComponentProps } from 'react-router-dom';
import CustomBreadcrumb from '../../components/common/Breadcrumb/CustomBreadcrumb';
import User from '../../models/user';
import { appFetch } from '../../services/fetch';

type TParams = { id: string };

function UserDetail({ match }: RouteComponentProps<TParams>): JSX.Element {

    const [user, setUser] = useState<User>({} as User);

    const breadcrumb = {
        path: `/users/${match.params.id}/detail`,
        value: 'Detalhar Usuário'
    }

    const breadcrumbs = [
        {
            path: '/',
            value: 'Home'
        },
        {
            path: '/users',
            value: 'Usuários'
        },
        breadcrumb
    ]

    useEffect(() => {
        if (match.params.id) getUser(match.params.id);
    }, [])

    const getUser = async (id: string) => {
        const user = await appFetch(`user/${id}`, 'GET') as User;
        setUser(user)
    }

    return (
        <div className='content-wrapper' style={{ overflowY: 'hidden' }}>
            <Row>
                <Col><h1>Detalhar Usuário</h1></Col>
                <Col>
                    <CustomBreadcrumb breadcrumbs={breadcrumbs}/>
                </Col>
            </Row>
            <hr/>
            <br/>
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        value={user.name}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicType">
                                    <Form.Label>Cpf</Form.Label>
                                    <Form.Control
                                        name="type"
                                        type="text"
                                        value={user.cpf}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicName">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="email"
                                        value={user.email}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicType">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control
                                        name="type"
                                        type="password"
                                        value="*********"
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBasicName">
                                    <Form.Label>Ativo</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="email"
                                        value={user.active ? 'Ativo' : 'Inativo'}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default UserDetail;



