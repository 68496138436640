import React from 'react';
import { Card, Button, FormControl, InputGroup } from 'react-bootstrap';
import { LEFT, RIGHT, TOP } from '../constants';
import { DelimitersCardParams } from '../interfaces';
import { WordsSelected, WordsUnselected } from '../styles';
import { getWord } from '../utils';


const DelimitersCard = ({
    setSelectedWords,
    setAddRange,
    setLeftRegex,
    setRightRegex,
    setTopRegex,
    leftWordsValues,
    rightWordsValues,
    wordsFromEachLineForTopDelimiter,
    addRange,
    selectedWords,
    wordValue,
    leftRegex,
    rightRegex,
    topRegex
}: DelimitersCardParams): JSX.Element => {
    const allTopWords = wordsFromEachLineForTopDelimiter.flatMap(w => w);

    const unselectWord = (index: number, position: string) => {
        if (position === LEFT) {
            setSelectedWords(
                selectedWords.map(word => { 
                    if(word.position === position && word.index <= index) {
                        return { word: word.word, index: word.index, position, selected: false, isDelimiter: false }
                    }
                    return word
                })
            )
        }

        if (position === RIGHT) {
            setSelectedWords(
                selectedWords.map(word => { 
                    if(word.position === position && word.index >= index) {
                        return { word: word.word, index: word.index, position, selected: false, isDelimiter: false }
                    }
                    return word
                })
            )
        }

        if (position === TOP) {
            setSelectedWords(
                selectedWords.map(word => { 
                    if(word.position === position && word.index === index) {
                        return { word: word.word, index: word.index, position, selected: false, isDelimiter: false }
                    }
                    return word
                })
            )
        }

    }

    const selectWord = (index: number, position: string, topWordLineIndex?: number) => {
        if (position === TOP) {
            const wordsFromTopLineIndex = topWordLineIndex !== undefined ? wordsFromEachLineForTopDelimiter[topWordLineIndex] : [];

            selectedWords.forEach(w => {
                const unselectWordsFromOtherLines = w.isDelimiter && w.position === TOP && !wordsFromTopLineIndex.includes(w.word);
                if (unselectWordsFromOtherLines) {
                    w.isDelimiter = false;
                    unselectWord(index, TOP);
                }
                if (w.index === index && w.position === TOP) {
                    w.isDelimiter = true;
                }
            });
        }
        const nextDelimiters = selectedWords.filter(word => word.position === position && word.isDelimiter && word.index > index)
        const prevDelimiters = selectedWords.filter(word => word.position === position && word.isDelimiter && word.index < index)

        const firstNextDelimiter = nextDelimiters[0];
        const lastPrevDelimiter = prevDelimiters[prevDelimiters.length - 1];

        if (nextDelimiters.length && prevDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === position && ((word.index >= index && word.index < firstNextDelimiter.index) || (word.index < index && word.index > lastPrevDelimiter.index)))
                        return { word: word.word, index: word.index, position, selected: false, isDelimiter: true }
                    return word
                })
            )
        }


        if (nextDelimiters.length && !prevDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === position && (word.index >= index && word.index < firstNextDelimiter.index))
                        return { word: word.word, index: word.index, position, selected: false, isDelimiter: true }
                    return word
                })
            )
        }


        if (!nextDelimiters.length && prevDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === position && (word.index <= index && word.index > lastPrevDelimiter.index))
                        return { word: word.word, index: word.index, position, selected: false, isDelimiter: true }
                    return word
                })
            )
        }

        if (!nextDelimiters.length && !prevDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === position && word.index === index)
                        return { word: word.word, index: word.index, position, selected: false, isDelimiter: true }
                    return word
                })
            )
        }
    }

    const renderDelimiters = (position: string) => {
        const delimiters = selectedWords.filter(word => word.position === position && word.isDelimiter) || []
        return delimiters.length ? delimiters.reduce((acc, curr) => `${acc} ${getWord(curr.word)}`, '') : ''
    }

    const renderWordsSelected = (value: string, index: number, position: string, topWordLineIndex?: number) => {
        if (addRange) {
            return (
                selectedWords.find(word => word.position === position && word.selected && word.index === index)
                ? <strong key={index}>{` ${value} `}</strong>
                : (
                    selectedWords.find(word => word.position === position && !word.isDelimiter && word.index === index)
                    ? <WordsUnselected style={{ pointerEvents: 'none', opacity: '0.5' }} key={index}>{value}</WordsUnselected>
                    : <WordsSelected style={{ pointerEvents: 'none', opacity: '0.5' }} key={index}>{value}</WordsSelected>
                )
            )
        } else {
            return (
                selectedWords.find(word => word.position === position && word.isDelimiter && word.index === index)
                ? <WordsSelected onClick={() => unselectWord(index, position)} key={index}>{value}</WordsSelected>
                : <WordsUnselected onClick={() => selectWord(index, position, topWordLineIndex)} key={index}>{value}</WordsUnselected>
            )
        }
    }

    const checkDelimiters = () => {
        const rightDelimiters = selectedWords.filter(word => word.isDelimiter && word.position === RIGHT)
        const leftDelimiters = selectedWords.filter(word => word.isDelimiter && word.position === LEFT)
        const topDelimiters = selectedWords.filter(word => word.isDelimiter && word.position === TOP);

        const lastLeftDelimiter = leftDelimiters[leftDelimiters.length - 1]
        const firstRightDelimiter = rightDelimiters[0]

        if (leftDelimiters.length && !rightDelimiters.length && !topDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === RIGHT) return { ...word, selected: true }
                    if (word.position === LEFT && word.index > lastLeftDelimiter.index) return { ...word, selected: true }
                    return word
                })
            )
        }

        if (!leftDelimiters.length && rightDelimiters.length && !topDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === LEFT) return { ...word, selected: true }
                    if (word.position === RIGHT && word.index < firstRightDelimiter.index) return { ...word, selected: true }
                    return word
                })
            )
        }

        if (leftDelimiters.length && rightDelimiters.length && !topDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === LEFT && word.index > lastLeftDelimiter.index) return { ...word, selected: true }
                    if (word.position === RIGHT && word.index < firstRightDelimiter.index) return { ...word, selected: true }
                    return word
                })
            )
        }

        //se tiver delimitadores acima, só poderá existir(caso necessite) delimitador a esquerda.
        if (topDelimiters.length) {
            setSelectedWords(
                selectedWords.map(word => {
                    if (word.position === RIGHT) return { ...word, selected: false, isDelimiter: false };
                    return word;
                })
            )
        }

        setAddRange(true)
    }

    const editDelimiters = () => {
        setSelectedWords(
            selectedWords.map(word => {
                if (word.position !== 'CENTER') return { ...word, selected: false }
                return word
            })
        )
        setAddRange(false)
    }

    const editRegexByDelimiter = (delimiterType: string, regex: string) => {
        // eslint-disable-next-line
        const delimitersRegex: any = {
            left: () => setLeftRegex(regex),
            right: () => setRightRegex(regex),
            top: () => setTopRegex(regex)
        };
        delimitersRegex[delimiterType]();
    }

    return (
        <React.Fragment>
            <Card>
                <Card.Body>
                    <span>Delimitadores ao lado da palavra</span>
                    <div style={{ margin: '0.5rem 0' }}>
                        <p>
                            {leftWordsValues.map((value, index) => renderWordsSelected(value, index, LEFT))}
                            <strong>{wordValue}</strong>
                            {rightWordsValues.map((value, index) => renderWordsSelected(value, index, RIGHT))}
                        </p>
                    </div>
                    <span>Delimitador acima da palavra (Escolha elementos de apenas uma linha e caso necessário)</span>
                    <div style={{ margin: '0.5rem 0' }}>
                        {wordsFromEachLineForTopDelimiter.map((arrayOfWords, topWordLineIndex) => {
                            return (<p key={topWordLineIndex}>
                                {arrayOfWords.map((word) => {
                                    const index = allTopWords.findIndex(w => w == word);
                                    return renderWordsSelected(getWord(word), index, TOP, topWordLineIndex);
                                })}
                            </p>)
                        })}
                    </div>
                    <div  style={{ width: '100%', marginTop: '1.5rem' }}>
                        <Button onClick={checkDelimiters} size="sm">Ok</Button>
                        <Button style={{ marginLeft: '0.5rem' }} onClick={editDelimiters} size="sm">Editar</Button>
                    </div>
                </Card.Body>
            </Card>
            <br/>
            <div>
                <div>
                    <span>Delimitador a esquerda: <strong>{addRange && renderDelimiters(LEFT) ? renderDelimiters(LEFT) : 'Não há delimitador selecionado'}</strong></span><br/>
                    <InputGroup size="sm" className="mb-3" style={{ width: '400px' }}>
                        <FormControl defaultValue={leftRegex} onChange={(event) => editRegexByDelimiter('left', event.target.value)} placeholder="Caso o delimitador varie, digite a regex que o representa"/>
                    </InputGroup>
                </div>
                <div>
                    <span>Delimitador a direita: <strong>{addRange && renderDelimiters(RIGHT) ? renderDelimiters(RIGHT) : 'Não há delimitador selecionado'}</strong></span><br/>
                    <InputGroup size="sm" className="mb-3" style={{ width: '400px' }}>
                        <FormControl defaultValue={rightRegex} onChange={(event) => editRegexByDelimiter('right', event.target.value)} placeholder="Caso o delimitador varie, digite a regex que o representa"/>
                    </InputGroup>
                </div>
                <div>
                    <span>Delimitador acima: <strong>{addRange && renderDelimiters(TOP) ? renderDelimiters(TOP) : 'Não há delimitador selecionado'}</strong></span><br/>
                    <InputGroup size="sm" className="mb-3" style={{ width: '400px' }}>
                        <FormControl defaultValue={topRegex} onChange={(event) => editRegexByDelimiter('top', event.target.value)} placeholder="Caso o delimitador varie, digite a regex que o representa"/>
                    </InputGroup>
                </div>
            </div>
            <br/>
        </React.Fragment>
    )
}

export default DelimitersCard;