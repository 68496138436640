import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Image, Button } from 'react-bootstrap';
import { faTimes, faExclamationTriangle, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appFetch, storageFetch } from '../../services/fetch';
import Url from '../../models/url';
import './styles.css';

interface ImagesModalParams {
    onCloseImagesModal(value: string) : void
    paths: string[]
}

const ImagesModal = ({onCloseImagesModal, paths}: ImagesModalParams): JSX.Element => {
    const [selectedPath, setSelectedPath] = useState('')
    const [srcs, setSrcs] = useState([] as string[])
    const [imgSelected, setImgSelected] = useState([] as boolean[])

    useEffect(() => {
        getSrcs()
    }, [])

    const getSrcs = async () => {
        const urls = await Promise.all(paths.map(async path =>await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url))
        const blobResponses = await Promise.all(urls.map(async ({ signedURL }) => await storageFetch(signedURL || '', 'GET') as Response))
        const blobs = await Promise.all(blobResponses.map(async response => await response?.blob()))
        const srcs = await Promise.all(blobs.map(blob => URL.createObjectURL(blob)))
        setSrcs(srcs)
        setImgSelected(paths.map((path, i) => i === 0))
        setSelectedPath(paths[0])
    }

    const handleSelectImage = (index: number) => {
        setImgSelected(paths.map((path, i) => index === i))
        setSelectedPath(paths[index])
    }

    const onViewFile = async (path: string) => {
        const { signedURL }  = await appFetch(`utils/getSignedUrlForDownload?path=${path}`, 'GET') as Url;
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = signedURL || '';
        link.click();
    }

    return (
        <React.Fragment>
            {selectedPath && <Card style={{
                boxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)',
                height: 'calc(100vh - 57px - 90px)'
            }}>
                <Card.Body>
                    <Row>
                        <Col onClick={() => onCloseImagesModal(selectedPath)} style={{ cursor: 'pointer', textAlign: 'end' }} xs={12} >
                            <FontAwesomeIcon icon={faTimes}/>
                        </Col>
                        <Col xs={12}>
                            <h5 style={{ marginBottom: '0' }}>Escolha um dos arquivos abaixo</h5>
                            <span style={{
                                fontSize: '0.8rem'
                            }}><FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;Esse arquivo será usado como padrão para extração das regras</span>
                            <hr/>
                        </Col>
                        <div style={{ overflowY: 'scroll', height: '60vh' }}>
                            <Row xs={1} md={6} style={{ margin: '0' }}>
                            {!!paths.length && paths.map((path, index)  => (
                                <Col key={path} style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <div style={{ position: 'relative' }}>
                                        <Image className={imgSelected[index] ? 'img-card-boder-selected' : 'img-card-boder'} src={srcs[index]} onClick={() => handleSelectImage(index)}></Image>
                                        <FontAwesomeIcon
                                            onClick={() => onViewFile(path)}
                                            icon={faEye}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                bottom: '10px',
                                                color: '#007bff',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                </Col>
                            ))}
                            </Row>
                        </div>
                    </Row>
                </Card.Body>
                <Card.Footer style={{ textAlign: 'center' }}>
                    <Button onClick={() => onCloseImagesModal(selectedPath)}>Ok</Button>
                </Card.Footer>
            </Card>}
        </React.Fragment>
    )
}

export default ImagesModal