import React, { useState } from 'react'
import Content from '../components/Content/Content'
import Header from '../components/Header/Header'

const Internal = (): JSX.Element => {
    const [redirectTo, setRedirectTo] = useState('')
    const onRedirect = (path: string) => {
        setRedirectTo(path)  
    }
    return (
        <React.Fragment>
            <Header onRedirect={onRedirect}/>
            <Content setRedirectTo={setRedirectTo} redirectTo={redirectTo} />
        </React.Fragment>
    )
}

export default Internal