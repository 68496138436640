import fetch from 'isomorphic-fetch';
import config from '../config';

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

const getHeaders = (token: string, customHeaders = {}) => {
    const authHeaders = {
        Authorization: `Bearer ${token}`
    }

    if (token) {
        return {
            ...defaultHeaders,
            ...authHeaders,
            ...customHeaders
        }
    }

    return defaultHeaders
}

const getOptions = (method: string, body = {}) => {
    const defaultOptions = { method }

    if (method === 'PUT' || method === 'POST') {
        return {
            ...defaultOptions,
            body: JSON.stringify(body)
        }
    }
    return defaultOptions
}

export const appFetch = async (url: string, method: string, customHeaders = {}, body = {}): Promise<unknown> => {
    const token = sessionStorage.getItem('me') || ''
    const options = getOptions(method, body);

    try {
        const response = await fetch(`${config.BASE_URL}/${url}`, {
            ...options,
            headers: getHeaders(token, customHeaders),
        })

        if (response.status === 204) {
            return null;
        }
        if (response.status >= 200 && response.status < 300) {
            return await response.json();
        }

        const error = new Error(response.statusText);
        throw error;

    } catch (error) {
        throw error;
    }
}


const getStorageOptions = (method: string, body: ReadableStream<Uint8Array>) => {
    const defaultOptions = { method }

    if (method === 'PUT' || method === 'POST') {
        return {
            ...defaultOptions,
            body
        }
    }
    return defaultOptions
}

export const storageFetch = async (url: string, method: string, customHeaders = {}, body = {} as ReadableStream<Uint8Array>): Promise<unknown> => {
    try {
        const options = getStorageOptions(method, body);

        const response = await fetch(url, {
            ...options,
            headers: {
                ...customHeaders
            },
        })

        if (response.status === 204) {
            return null;
        }
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        const error = new Error(response.statusText);
        throw error;

    } catch (error) {
        throw error;
    }
}
